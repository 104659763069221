import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";

import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { BarcodeType, barcodeLabelByValue } from "models/enums/BarcodeType";

interface IProps extends IInputWrapperProps {
    id?: string;
    placeholder?: string;
    testId?: string;
    selectedBarcodeType?: BarcodeType;
    wrapperInputTestId?: string;
    onChange: (barcodeType: BarcodeType) => void;
}

const BarcodeTypeDropdown: React.FC<IProps> = (props) => {
    const {
        id,
        placeholder,
        className,
        wrapperIcon: icon,
        wrapperLabel: label,
        testId,
        wrapperInputTestId: inputTestId,
        boldLabel,
        selectedBarcodeType,
        onChange,
    } = props;

    const { t } = useTranslation();

    const barcodeTypeOptions: IValueLabelItem<BarcodeType, string>[] =
        useMemo(() => {
            return Object.values(BarcodeType)
                .filter((node): node is BarcodeType => typeof node === "number")
                .map((node) => {
                    const barcodeLabel = barcodeLabelByValue[node];
                    return {
                        label: barcodeLabel,
                        value: node,
                    };
                });
        }, []);

    const [selectedOption, setSelectedOption] = useState<
        IValueLabelItem<BarcodeType, string> | undefined | null
    >(null);

    useEffect(() => {
        setSelectedOption(
            barcodeTypeOptions.find(
                (option) => option.value === selectedBarcodeType,
            ),
        );
    }, [selectedBarcodeType]);

    const handleOnChange = (
        option?: IValueLabelItem<BarcodeType, string> | null,
    ) => {
        setSelectedOption(option);
        if (option) {
            onChange(option.value);
        }
    };

    return (
        <InputWrapper
            htmlFor={id}
            className={className}
            wrapperIcon={icon}
            wrapperLabel={label ?? t(k.SELECT_BARCODE_TYPE)}
            boldLabel={boldLabel}
            wrapperInputTestId={inputTestId}
        >
            <SelectDropdown<
                IValueLabelItem<BarcodeType, string>,
                false,
                GroupBase<IValueLabelItem<BarcodeType, string>>
            >
                inputId={id}
                value={selectedOption}
                isSearchable
                placeholder={placeholder ?? t(k.SELECT)}
                options={barcodeTypeOptions}
                testId={testId}
                closeMenuOnSelect
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};

export default BarcodeTypeDropdown;
