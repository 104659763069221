import axios from "axios";
import { Store } from "redux";

import { IAppState } from "../../../store/IAppState";
import { IPublicClientApplication } from "@azure/msal-browser";

export const configureErrorHandler = (
    store: Store<IAppState>,
    msalInstance: IPublicClientApplication,
) => {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};
