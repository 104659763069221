import "./ActionAndUser.scss";

import classnames from "classnames";

import Avatar from "../avatar/Avatar";

interface IProps {
    user?: string;
    showAvatar?: boolean;
    className?: string;
    action?: string;
}

const ActionAndUser = ({
    action,
    user,
    showAvatar = true,
    className,
}: IProps) => {
    const classes = classnames("action-and-user", {
        [className ?? ""]: className,
    });
    return action ? (
        <div className={classes}>
            {action}
            <div className="action-and-user__user">
                {showAvatar && <Avatar name={user} />}
                {user}
            </div>
        </div>
    ) : (
        <div className="action-and-user__user">
            {showAvatar && <Avatar name={user} />}
            {user}
        </div>
    );
};

export default ActionAndUser;
