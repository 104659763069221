import { BarcodeType } from "models/enums/BarcodeType";

function isDigitsOnly(value: string): boolean {
    return /^[0-9]+$/.test(value);
}

function validateEan(value: string, requiredLength: number): boolean {
    value = value.trim();
    if (value.length !== requiredLength) return false;
    if (!isDigitsOnly(value)) return false;

    const dataDigits = value.slice(0, requiredLength - 1);
    const providedCheckDigit = parseInt(value[requiredLength - 1], 10);

    let sum = 0;
    for (let i = 0; i < dataDigits.length; i++) {
        const digitIndex = dataDigits.length - 1 - i;
        const digit = parseInt(dataDigits[digitIndex], 10);
        if (i % 2 === 0) {
            sum += digit * 3;
        } else {
            sum += digit;
        }
    }

    const expectedCheckDigit = (10 - (sum % 10)) % 10;
    return expectedCheckDigit === providedCheckDigit;
}

function validateCode39(value: string): boolean {
    const trimmed = value.trim().toUpperCase();
    if (!trimmed) return false;
    const code39Regex = /^[0-9A-Z\-\.\ \$\/\+\%]+$/;
    return code39Regex.test(trimmed);
}

function validateCode128(value: string): boolean {
    const trimmed = value.trim();
    return trimmed.length > 0;
}

export function ValidateBarcode(value: string, type: BarcodeType): boolean {
    if (!value || value.trim().length === 0) {
        return false;
    }
    switch (type) {
        case BarcodeType.EAN13:
            return validateEan(value, 13);
        case BarcodeType.EAN8:
            return validateEan(value, 8);
        case BarcodeType.CODE39:
            return validateCode39(value);
        case BarcodeType.CODE128:
            return validateCode128(value);
        default:
            return false;
    }
}
