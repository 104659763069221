import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IActivityProps } from "./IActivityProps";
import BarcodeTypeDropdown from "common/components/barcode-type-dropdown/BarcodeTypeDropdown";
import { BarcodeIcon } from "common/components/icons";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { BarcodeType } from "models/enums/BarcodeType";

const ActivityBarcodeField: React.FC<IActivityProps> = (props) => {
    const { activity, typeField, isEditMode } = props;

    const { t } = useTranslation();

    const handleChange = (barcodeType: BarcodeType) => {
        const name = "barcodeType";
        const value = barcodeType as unknown as string;

        const args: IInputChangeEvent<string> = {
            id: name,
            name,
            value,
        };

        props.onChange(args);
    };

    const handleOnChangeAcceptMultiple = (
        event: IInputChangeEvent<boolean, any>,
    ) => {
        const { name, value, id } = event;

        props.onChange({
            id,
            name,
            value,
        });
    };

    const typeList: React.ReactElement = isEditMode ? (
        <div className="step-activity-options">
            <BarcodeTypeDropdown
                id="barcodeType"
                boldLabel
                selectedBarcodeType={activity.barcodeType}
                onChange={handleChange}
            />
            <div className="step-activity-options--label">{t(k.OPTIONS)}</div>
            <div className="step-activity-options--field">
                <Checkbox
                    id="acceptMultiple"
                    name="acceptMultiple"
                    testId="acceptMultiple"
                    value={!!activity.acceptMultiple}
                    label={t(k.ACCEPT_MULTIPLE_BARCODES)}
                    onChange={handleOnChangeAcceptMultiple}
                />
            </div>
        </div>
    ) : (
        <React.Fragment />
    );

    return typeField({
        typeList,
        typeLabel: `${t(k.LABEL)} ${t(k.FOR_)} ${t(k.BARCODES)}`,
    });
};

export default ActivityBarcodeField;
