import "./ReportTableCell.scss";

import React, { HTMLAttributes, MouseEvent } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import k from "i18n/keys";

import { ChecklistReportMatrixCellType } from "../report-table-details/api/IChecklistReportResponse";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import Barcode from "common/components/barcode/Barcode";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { SearchIcon } from "common/components/icons";
import Link from "common/components/links/Link";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import { TableCell } from "common/components/table";
import ValueWrapper, {
    CheckboxValueWrapper,
} from "common/components/value-wrapper/ValueWrapper";
import { convertToDate } from "common/utils/time";
import IDictionary from "common/viewModels/IDictionary";
import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";
import { ActivityType } from "models/enums/ActivityType";
import { BarcodeType } from "models/enums/BarcodeType";
import IBarcodePreviewRequest from "store/barcode/IBarcodePreviewRequest";
import { showBarcodePreview } from "store/barcode/actions";
import { showBlobImage, showImage, showImageById } from "store/images/actions";

interface IValueWrapperProps extends HTMLAttributes<HTMLDivElement> {
    isHighlighted?: boolean;
    children?: React.ReactNode;
}

const _ValueWrapper = ({ isHighlighted, children }: IValueWrapperProps) => {
    return (
        <div className={isHighlighted ? "table-cell__match-cell" : undefined}>
            {children}
        </div>
    );
};

const NotAvailable = () => <span className="text--muted">N/A</span>;

interface IOwnProps {
    isTopLevel: boolean;

    isCellBusy: boolean;

    value: ChecklistReportMatrixCellType;

    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;

    highlightedActivityInstanceIds: IDictionary<boolean>;

    onBarcodeSearch: (barcode: string) => void;
}

interface IDispatchProps {
    showImagePreviewById: (imgId: string) => void;
    showBlobImagePreview: (value: IBlobSrcType) => void;
    showImagePreviewByValue: (value: string) => void;
    showBarcodePreview: (value: IBarcodePreviewRequest) => void;
}

type Props = IOwnProps & IDispatchProps;

type ReportTableCellType = React.FC<Props> & {
    NotAvailableMessage: React.FC;
};

const ReportTableCell: ReportTableCellType = (props) => {
    const {
        highlightedActivityInstanceIds,
        selectedCustomListItemsNames,
        isTopLevel,
        isCellBusy,
    } = props;

    const { value, isCellExpanded, rowSpan } = props.value;

    const { t } = useTranslation();

    const modalManagerContext = React.useContext(ModalManagerContext);

    if (!value) {
        if (isTopLevel) {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                >
                    <NotAvailable />
                </TableCell>
            );
        }

        return (
            <TableCell
                className={
                    isCellExpanded ? "report-table-cell__expanded" : undefined
                }
                rowSpan={rowSpan}
                isBusy={isCellBusy}
            ></TableCell>
        );
    }

    const handlePreviewPhoto = (
        e: MouseEvent<HTMLSpanElement> & {
            target: { dataset: { id?: string } };
        },
    ) => {
        const pictureId = e.target.dataset.id;

        if (pictureId) {
            if (isTopLevel) {
                props.showImagePreviewById(pictureId);
            } else {
                const image = value.values?.find((x) => x.value === pictureId);

                if (image?.label) {
                    props.showImagePreviewByValue(image.label);
                }
            }
        }
    };

    const handlePreviewCustomListItem = (id?: string) => {
        if (id) {
            const payload: IShowModalPayload = {
                id,
                type: ModalTypes.custom_list,
                callerAddress: location.pathname,
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    const handleBarcodePreview = (value: string, type: BarcodeType) => {
        const request: IBarcodePreviewRequest = {
            value,
            type,
        };

        props.showBarcodePreview(request);
    };

    const handleOnBarcodeSearch = (
        e: MouseEvent<HTMLDivElement> & {
            target: { dataset: { barcode?: string } };
        },
    ) => {
        const { barcode } = e.target.dataset;

        if (barcode) {
            props.onBarcodeSearch(barcode);
        }
    };

    switch (value.type) {
        case ActivityType.Photo: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <_ValueWrapper
                            key={index}
                            isHighlighted={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                            }
                        >
                            {x.isCompleted ? (
                                <span
                                    className="preview-link"
                                    data-id={x.value}
                                    onClick={handlePreviewPhoto}
                                >
                                    {t(k.PHOTO_PREVIEW)}
                                </span>
                            ) : undefined}
                        </_ValueWrapper>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.GenerateBarcode:
        case ActivityType.Barcodes: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <div
                            key={index}
                            className="report-table-cell__barcode-wrapper"
                        >
                            <_ValueWrapper
                                isHighlighted={
                                    highlightedActivityInstanceIds[
                                        x.activityInstanceId
                                    ]
                                }
                            >
                                {x.label && (
                                    <Barcode
                                        value={x.label}
                                        barcodeType={value.barcodeType}
                                        onClick={handleBarcodePreview}
                                    />
                                )}
                            </_ValueWrapper>

                            {x.label && (
                                <div
                                    className="report-table-cell__search"
                                    data-barcode={x.label}
                                    onClick={handleOnBarcodeSearch}
                                >
                                    <SearchIcon />
                                </div>
                            )}
                        </div>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.Tasklist: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <CheckboxValueWrapper
                            key={index}
                            className={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                                    ? "table-cell__match-cell"
                                    : undefined
                            }
                            checked={x.isCompleted}
                        >
                            {x.label ?? ""}
                        </CheckboxValueWrapper>
                    ))}
                </TableCell>
            );
        }
        case ActivityType.Customers:
        case ActivityType.Suppliers:
        case ActivityType.Items:
        case ActivityType.Users:
        case ActivityType.Positions:
        case ActivityType.Competencies: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <ValueWrapper
                            key={index}
                            className={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                                    ? "table-cell__match-cell"
                                    : undefined
                            }
                            isArchived={x.isArchived}
                            isDeleted={x.isDeleted}
                        >
                            {x.label ?? ""}
                        </ValueWrapper>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.CustomList: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => {
                        const customListItem =
                            selectedCustomListItemsNames?.[x.value];

                        if (customListItem) {
                            return (
                                <ValueWrapper
                                    key={index}
                                    id={customListItem.value}
                                    className={
                                        highlightedActivityInstanceIds[
                                            x.activityInstanceId
                                        ]
                                            ? "table-cell__match-cell"
                                            : undefined
                                    }
                                    isArchived={customListItem.isArchived}
                                    isDeleted={customListItem.isDeleted}
                                    onClick={handlePreviewCustomListItem}
                                >
                                    {customListItem.label}
                                </ValueWrapper>
                            );
                        }
                    })}
                </TableCell>
            );
        }

        case ActivityType.DateTime: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <span key={index}>
                            <FormattedDate
                                date={convertToDate(x.label ?? undefined)}
                            />
                        </span>
                    ))}
                </TableCell>
            );
        }

        case ActivityType.Hyperlink: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                    noWrap
                >
                    {value.values?.map((x, index) => (
                        <span key={index}>
                            {x.label && <Link to={x.label}>{x.label}</Link>}
                        </span>
                    ))}
                </TableCell>
            );
        }

        default: {
            return (
                <TableCell
                    className={
                        isCellExpanded
                            ? "report-table-cell__expanded"
                            : undefined
                    }
                    rowSpan={rowSpan}
                    flexColumn
                >
                    {value.values?.map((x, index) => (
                        <_ValueWrapper
                            key={index}
                            isHighlighted={
                                highlightedActivityInstanceIds[
                                    x.activityInstanceId
                                ]
                            }
                        >
                            <div
                                className="table-cell__truncate"
                                title={x.label ?? undefined}
                            >
                                {x.label}
                            </div>
                        </_ValueWrapper>
                    ))}
                </TableCell>
            );
        }
    }
};

ReportTableCell.NotAvailableMessage = NotAvailable;

const actions: IDispatchProps = {
    showImagePreviewById: showImageById,
    showImagePreviewByValue: showImage,
    showBlobImagePreview: showBlobImage,
    showBarcodePreview,
};

export default connect(null, actions)(ReportTableCell);
