export enum BarcodeType {
    EAN13,
    EAN8,
    CODE128,
    CODE39,
}

export const barcodeLabelByValue = {
    [BarcodeType.EAN13]: "EAN13",
    [BarcodeType.EAN8]: "EAN8",
    [BarcodeType.CODE128]: "CODE128",
    [BarcodeType.CODE39]: "CODE39",
};
