import { EPages } from "./EPages";
import { Permissions } from "./Permissions";
import { Roles } from "./Roles";

export const improvementFormRoles = [
    Roles.ADMINISTRATOR,
    Roles.DEVIATION_MANAGER,
];

export const administratorOrMapperRoles = [Roles.ADMINISTRATOR, Roles.MAPPER];

export const administratorRoles = [Roles.ADMINISTRATOR];

export const viewORARoles = Permissions[EPages.ORA].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewSARoles = Permissions[EPages.SA].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewCMRoles = Permissions[EPages.CM].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewPositionRoles = Permissions[EPages.POSITION].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewUserRoles = Permissions[EPages.USER].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewKPIRoles = Permissions[EPages.KPI].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const improvementDashboardRoles = Permissions[
    EPages.IMPROVEMENT_DASHBOARD
].allRoles.concat(Roles.ADMINISTRATOR);

export const checklistReportRoles = Permissions[
    EPages.CHECKLIST_REPORT
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewStakeholderRoles = Permissions[
    EPages.STAKEHOLDERS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewLegislationsRoles = Permissions[
    EPages.LEGISLATIONS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewImpactGradingsRoles = Permissions[
    EPages.IMPACT_GRADINGS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewCustomerRequirementsRoles = Permissions[
    EPages.CUSTOMER_REQUIREMENTS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewSuppliersRoles = Permissions[EPages.SUPPLIERS].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewCustomerRoles = Permissions[EPages.CUSTOMERS].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const editSuppliersRoles = (
    Permissions[EPages.SUPPLIERS].editRoles ?? []
).concat(Roles.ADMINISTRATOR);

export const viewPurchaseOrdersRoles = Permissions[
    EPages.PURCHASE_ORDERS
].allRoles.concat(Roles.ADMINISTRATOR);

export const editPurchaseOrdersRoles = (
    Permissions[EPages.PURCHASE_ORDERS].editRoles ?? []
).concat(Roles.ADMINISTRATOR);

export const viewItemsRoles = Permissions[EPages.ITEMS].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewTeamsRoles = Permissions[EPages.TEAMS].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const viewTrainingPlansRoles = Permissions[
    EPages.TRAINING_PLANS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewEquipmentRoles = Permissions[EPages.EQUIPMENT].allRoles.concat(
    Roles.ADMINISTRATOR,
);

export const editEquipmentRoles = (
    Permissions[EPages.EQUIPMENT].editRoles ?? []
).concat(Roles.ADMINISTRATOR);

export const viewEnvironmentAspectsRoles = Permissions[
    EPages.ENVIRONMENT_ASPECTS
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewCompetenciesRoles = Permissions[
    EPages.COMPETENCIES
].allRoles.concat(Roles.ADMINISTRATOR);

export const viewGoalsRoles = Permissions[EPages.GOALS].allRoles.concat(
    Roles.ADMINISTRATOR,
);
