import { parseDecimalWithLocale } from "AppLocale";
import { v4 as uuidv4 } from "uuid";

import { IKpiTargetDTO } from "./IKpiTargetDTO";
import { IKpiTargetValueDTO } from "./IKpiTargetValueDTO";
import { Months, MonthsOrder } from "models/enums/Months";

const getDefaultTargetQuantity = (date: Date, data: IKpiTargetDTO) => {
    const startingYear = date.getFullYear();
    const startingMonth = date.getMonth();
    const revertedMonths = [...MonthsOrder].reverse();

    let year: number = startingYear;

    do {
        const targetValueIds = data.kpiTargetValueIdsByYear?.[year];

        if (targetValueIds) {
            for (const month of revertedMonths) {
                if (year === startingYear && month >= startingMonth) {
                    continue;
                }

                const id = targetValueIds[month];

                const kpiTargetValue = data.values[id];

                if (kpiTargetValue?.quantityTarget !== undefined) {
                    return kpiTargetValue.quantityTarget;
                }
            }
        }

        year--;
    } while (data.yearList.includes(year));
};

export const newYear = (param?: {
    nextYear: number;
    sourceKpi?: IKpiTargetDTO;
}) => {
    const { nextYear, sourceKpi } = param ?? {
        nextYear: new Date().getFullYear(),
    };

    let kpiTargetValues: Record<string, IKpiTargetValueDTO> = {};

    let kpiTargetValueIds: string[] = [];

    const existingTargetValues = sourceKpi?.kpiTargetValueIdsByYear?.[nextYear];

    let targetValues: IKpiTargetValueDTO[] | undefined;

    if (existingTargetValues) {
        const existingIds = existingTargetValues;

        if (existingIds && existingIds.length === MonthsOrder.length) {
            targetValues = [];

            for (const id of existingIds) {
                const item = sourceKpi?.values[id];

                if (item) {
                    targetValues.push(item);
                }
            }

            if (targetValues.length === MonthsOrder.length) {
                kpiTargetValueIds = existingIds;
            } else {
                targetValues = undefined;

                kpiTargetValueIds = [];
            }
        }
    } else {
        targetValues = [];

        const ids: string[] = [];

        for (const month of MonthsOrder) {
            const item: IKpiTargetValueDTO = {
                id: uuidv4(),
                date: new Date(nextYear, month, 1),
            };

            targetValues.push(item);

            ids.push(item.id);
        }

        kpiTargetValueIds = ids;
    }

    if (targetValues) {
        let canUpdateWithPreviousDecemberValue = true;

        for (const item of targetValues) {
            kpiTargetValues[item.id] = item;

            if (canUpdateWithPreviousDecemberValue && sourceKpi) {
                const sourceYear = nextYear - 1;

                const defaultTargetValue =
                    sourceKpi.kpiTargetValueIdsByYear?.[sourceYear];

                if (defaultTargetValue) {
                    const defaultValuesIds = defaultTargetValue;

                    if (
                        defaultValuesIds &&
                        defaultValuesIds.length === MonthsOrder.length
                    ) {
                        const decemberTargetValueId =
                            defaultValuesIds[MonthsOrder.length - 1];

                        const decemberTargetValue =
                            sourceKpi.values[decemberTargetValueId];

                        if (item.hasQuantityTarget) {
                            canUpdateWithPreviousDecemberValue = false;
                        } else {
                            item.quantityTarget =
                                decemberTargetValue?.quantityTarget;
                        }
                    }
                }
            }
        }
    }

    return {
        year: nextYear,
        kpiTargetValues,
        kpiTargetValueIds,
    };
};

export const buildNextTargetValues = (
    id: string,
    value: string,
    existingValue: IKpiTargetValueDTO,
    prev: IKpiTargetDTO,
) => {
    const nextQuantityTarget = parseDecimalWithLocale(value);

    const isValid = !isNaN(nextQuantityTarget);

    const isEmpty = isValid ? false : value.trim() === "";
    const { date } = existingValue;
    const year = date.getFullYear();
    const month = date.getMonth();

    const quantityTarget = isEmpty
        ? getDefaultTargetQuantity(date, prev)
        : isValid
          ? nextQuantityTarget
          : existingValue.quantityTarget;

    const hasQuantityTarget = isEmpty
        ? false
        : isValid
          ? true
          : existingValue.hasQuantityTarget;

    const nextExistingTargetValueIds = prev.kpiTargetValueIdsByYear?.[year];

    const updatedValues: {
        stop: boolean;
        data: Record<string, IKpiTargetValueDTO>;
    } = {
        stop: false,
        data: {
            [id]: {
                ...existingValue,

                quantityTarget,

                hasQuantityTarget,
            },
        },
    };

    if (month !== Months.December && nextExistingTargetValueIds) {
        nextExistingTargetValueIds.slice(month + 1).reduce<{
            stop: boolean;
            data: Record<string, IKpiTargetValueDTO>;
        }>((map, currentId) => {
            if (map.stop === false) {
                const currentValue = prev.values[currentId];

                if (currentValue?.hasQuantityTarget) {
                    map.stop = true;
                } else if (currentValue) {
                    map.data[currentId] = {
                        ...currentValue,

                        quantityTarget,
                    };
                }
            }

            return map;
        }, updatedValues);
    }

    return updatedValues.data;
};
