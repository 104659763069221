import "./ImagePreview.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import k from "i18n/keys";

import ImageCard from "./ImageCard";
import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";
import { showBlobImage, showImage, showImageById } from "store/images/actions";

interface IOwnProps {
    value?: string;

    imgId?: string;

    blobSrc?: IBlobSrcType;

    completedAt?: Date;
}

interface IDispatchProps {
    showPreview: (value: string) => void;
    showPreviewById: (imgId: string) => void;
    showBlobPreview: (blobSrc: IBlobSrcType) => void;
}

type Props = IOwnProps & IDispatchProps;

const ImagePreview: React.FC<React.PropsWithChildren<Props>> & {
    Renderer: React.FC<React.PropsWithChildren<Props>>;
} = (props) => {
    const { value, imgId, blobSrc, completedAt } = props;

    const { t } = useTranslation();

    const showModalPreview = () => {
        if (value) {
            props.showPreview(value);
        } else if (blobSrc) {
            props.showBlobPreview(blobSrc);
        } else if (imgId) {
            props.showPreviewById(imgId);
        }
    };

    const imgSrc = value || imgId || blobSrc;

    if (!imgSrc) {
        return null;
    }

    return (
        <div className="image-preview__wrapper">
            <ImageCard
                value={value}
                imgId={imgId}
                blobSrc={blobSrc}
                completedAt={completedAt}
                onClick={showModalPreview}
            >
                <span>{t(k.CLICK_TO_PREVIEW)}</span>
            </ImageCard>
        </div>
    );
};

ImagePreview.Renderer = ImagePreview;

const actions: IDispatchProps = {
    showPreview: showImage,
    showPreviewById: showImageById,
    showBlobPreview: showBlobImage,
};

export default connect(null, actions)(ImagePreview);
