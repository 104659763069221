import "./MakeActivityRequired.scss";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import RadioButton from "common/components/input-components/radioButton/RadioButton";
import InputActionWrapper from "components/common/actions/inputActions/InputActionWrapper";

interface IProps {
    value?: boolean;
    isDisabled?: boolean;
    testId?: string;
    onClick: (value: boolean) => void;
}

const MakeActivityRequired = (props: IProps) => {
    const { value, isDisabled } = props;

    const { t } = useTranslation();

    const handleChange = () => {
        props.onClick(!value);
    };

    return (
        <InputActionWrapper label={t(k.MAKE_ACTIVITY_REQUIRED)} boldLabel>
            <div className="make-activity-required">
                <RadioButton
                    value={!!value}
                    label={t(k.YES)}
                    disabled={isDisabled}
                    onChange={handleChange}
                />
                <RadioButton
                    value={!value}
                    label={t(k.NO)}
                    disabled={isDisabled}
                    onChange={handleChange}
                />
            </div>
        </InputActionWrapper>
    );
};

export default MakeActivityRequired;
