import "./InputAction.scss";
import "./InputActionTextField.scss";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IInputActionProps } from "../IInputActionProps";
import ActionTextField from "./components/ActionTextField";
import { ActivityType } from "models/enums/ActivityType";

const InputActionTextField = (props: IInputActionProps) => {
    const {
        actionType,
        disabled,
        editMode,
        inputActions,
        inputRefs,
        editable = true,
    } = props;

    const inputAction = inputActions?.[0];
    const { t } = useTranslation();

    const isMultiline = actionType === ActivityType.Text;

    const handleOnBlur = (id: string, name?: string) => {
        props.onSaveInputAction?.();
        props.onBlur?.(id, name);
    };

    return (
        <div className="action-item-options">
            <ActionTextField
                id={inputAction?.id}
                name="value"
                inputRef={inputRefs?.[inputAction?.id]}
                value={inputAction?.value ?? ""}
                disabled={disabled}
                editMode={editMode}
                editable={editable}
                noMinWidth
                placeholder={t(k.ENTER_HERE)}
                multiline={isMultiline}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onBlur={handleOnBlur}
            />
        </div>
    );
};

export default InputActionTextField;
