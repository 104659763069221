import axios from "axios";

import { GoalAccessRoleEnum } from "./EGoalAccessRole";
import { IGoalDTO } from "./IGoalDTO";
import { IGoalFilter } from "./IGoalFilter";
import { IServerGoalPageFilter } from "./IGoalPageFilters";
import { IGoalRequest } from "./IGoalRequest";
import { IGoalStatusCommentRequest } from "./IGoalStatusCommentRequest";
import { IGoalStatusRequest } from "./IGoalStatusRequest";
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    IInitialFetchOptions,
    useInitialFetch,
} from "common/utils/query/useInitialFetch";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { ISortRequest } from "http/requests/ISortRequest";

const baseUrl = "/api/goal";

const goalKeys = {
    all: ["goals"] as const,
    getTableSet: (filter?: IServerGoalPageFilter) =>
        [...goalKeys.all, "goal-table-set", filter] as const,
    getModifiableList: (id?: string) =>
        [...goalKeys.all, "goal-modifiable", id] as const,
    goalById: (id?: string) => [...goalKeys.all, "goal-byId", id] as const,
    getByIds: (filter?: IGoalFilter) =>
        [...goalKeys.all, "goals-byIds", filter] as const,
    getAccessRole: (id?: string) =>
        [`goal-access-role${id ? "-" + id : ""}`, id] as const,
    getViewAsAccessRole: (id?: string, filter?: IServerGoalPageFilter) =>
        [
            `goal-view-as-access-role${id ? "-" + id : ""}`,
            JSON.stringify(filter),
        ] as const,
};

export const useGoalTableSet = (
    { enabled, syncData }: IInitialFetchOptions,
    filter: IServerGoalPageFilter,
) =>
    useInitialFetch(
        goalKeys.getTableSet(filter),
        async () => {
            const url = `${baseUrl}/table-set`;
            const response = await axios.post<
                ITableSetWithOptions<IGoalDTO, string>
            >(url, filter);

            return response.data;
        },
        { enabled, syncData },
    );

export const useGoalModifiableList = (id?: string, enabled?: boolean) => {
    const url = id
        ? `${baseUrl}/modifiable-list/${id}`
        : `${baseUrl}/modifiable-list`;

    return useQuery({
        queryKey: goalKeys.getModifiableList(id),
        queryFn: async () => {
            const response = await axios.get<IGoalDTO[]>(url);

            return response.data;
        },
        enabled,
    });
};

export const useSortMeasurementsMutation = () => {
    const queryClient = useQueryClient();
    const url = `${baseUrl}/sortMeasurements`;

    return useMutation({
        mutationFn: async (variables: { request: ISortRequest }) => {
            try {
                const response = await axios.post<string>(
                    url,
                    variables.request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: goalKeys.all });
            queryClient.invalidateQueries({
                queryKey: goalKeys.goalById(variables.request.parentId),
            });
        },
    });
};

export const useSortGoalsMutation = (sortSub?: boolean) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { request: ISortRequest }) => {
            const url = sortSub
                ? `${baseUrl}/sortSubgoals`
                : `${baseUrl}/sortGoals`;

            try {
                const response = await axios.post<string>(
                    url,
                    variables.request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({ queryKey: goalKeys.all });
            if (variables.request.parentId) {
                queryClient.invalidateQueries({
                    queryKey: goalKeys.goalById(variables.request.parentId),
                });
            }
        },
    });
};

export const useSaveGoalMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IGoalRequest;
        }) => {
            let result: string;
            const { isCreate, value } = variables;

            if (isCreate) {
                const response = await axios.post<string>(baseUrl, value);

                result = response.data;
            } else {
                const response = await axios.put<string>(
                    `${baseUrl}/${value.id}`,
                    value,
                );

                result = response.data;
            }
            return result;
        },

        onSuccess: (goalId) => {
            queryClient.invalidateQueries({
                queryKey: goalKeys.goalById(goalId),
            });
            queryClient.invalidateQueries({ queryKey: goalKeys.all });
            queryClient.invalidateQueries({
                queryKey: goalKeys.getModifiableList(goalId),
            });
        },
    });
};

export const useGoalViewAsAccessRole = (
    goalId?: string,
    filter?: IServerGoalPageFilter,
) => {
    const url = goalId
        ? `${baseUrl}/access-role/${goalId}`
        : `${baseUrl}/access-role`;

    return useQuery({
        queryKey: goalKeys.getViewAsAccessRole(goalId, filter),

        queryFn: async (context) => {
            const response = await axios.post<GoalAccessRoleEnum>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },

        enabled:
            Object.values(filter ?? {}).some((value) => value !== undefined) &&
            filter?.viewAs !== undefined,
    });
};

export const useGoalAccessRole = (goalId?: string) => {
    const url = `${baseUrl}/access-role/${goalId}`;

    return useQuery({
        queryKey: goalKeys.getAccessRole(goalId),
        queryFn: async (context) => {
            const response = await axios.get<GoalAccessRoleEnum>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        enabled: !!goalId,
    });
};

export const useDeleteGoalMutation = () => {
    return useMutation({
        mutationFn: async (id?: string) => {
            try {
                const response = await axios.delete<string>(`${baseUrl}/${id}`);

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useArchiveGoalMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: IArchiveRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/archive`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: goalKeys.goalById(data.Data),
            });
        },
    });
};

export const useGoalListByIds = (filter?: IGoalFilter) => {
    const url = `${baseUrl}/list-by-ids`;

    return useQuery({
        queryKey: goalKeys.getByIds(filter),
        queryFn: async (context) => {
            const response = await axios.post<IGoalDTO[]>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },
    });
};

export const useGoalById = (id?: string) => {
    const url = `${baseUrl}/${id}`;

    return useQuery({
        queryKey: goalKeys.goalById(id),

        queryFn: async () => {
            const response = await axios.get<IGoalDTO>(url);

            return response.data;
        },

        enabled: id !== undefined && id !== "",
        placeholderData: keepPreviousData,
    });
};

export const useChangeGoalStatusMutation = () => {
    return useMutation({
        mutationFn: async (request: IGoalStatusRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/changeStatus`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useChangeGoalStatusCommentMutation = () => {
    return useMutation({
        mutationFn: async (request: IGoalStatusCommentRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/changeStatusComment`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};
