import "./RegistrationStep.scss";

import {
    Context,
    Dispatch,
    PropsWithRef,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LocaleId } from "AppLocale";
import { i18n } from "i18next";
import { debounce } from "lodash";
import { v4 } from "uuid";
import * as yup from "yup";

import k from "i18n/keys";

import ImpactedProcessesCard from "../ImpactedProcessesCard";
import { createValidationSchema } from "../ImprovementListedContent/ImprovementListedContent.utils";
import { IImprovementContext } from "../ImprovementListedContent/models/IImprovementContext";
import { filterDictionaryByValue } from "./utils";
import { UseMutationResult } from "@tanstack/react-query";
import { ITreeListOption } from "common/ITreeListOption";
import { IValueLabelItem } from "common/IValueLabelItem";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { improvementSchemas } from "common/components/submit-improvement-card/ValidateImprovement";
import { errorToMessageReducer } from "common/components/validation/yupValidationErrorUtili";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { IImpactGradingOption } from "components/impact-grading-page/api/IImpactGradingOption";
import {
    ImpactGradingEnum,
    getImpactGradingOptions,
} from "components/impact-grading-page/api/ImpactGradingEnum";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import ImpactGradingCard from "components/impact-grading-page/components/inputs/ImpactGradingCard";
import { IImpactedProcessUpdateRequest } from "components/improvements/api/IImpactedProcessUpdateRequest";
import { IImpactedProcessValue } from "components/improvements/api/IImpactedProcessValue";
import ITemplateVersionConfigurationUsersDto from "components/improvements/api/ITemplateVersionConfigurationUsersDto";
import ITemplateVersionConfigurationUsersRequest from "components/improvements/api/ITemplateVersionConfigurationUsersRequest";
import {
    useGetResponsibleUsersForTemplateByTemplateVersionIdMutation,
    useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation,
    useSelectedImpactValuesByImprovementId,
    useUpdateImpactedProcessMutation,
    useUpdateImprovementMutation,
} from "components/improvements/api/hooks";
import { mergeStringArraysAndDistinct } from "components/improvements/api/utils";
import { usePublishedUserOptions } from "components/users/api/hooks";
import IImprovementInvolvedUserRequest from "http/requests/IImprovementInvolvedUserRequest";
import IImprovementRequest from "http/requests/IImprovementRequest";
import IImprovementUpdateRequest from "http/requests/IImprovementUpdateRequest";
import { IAppState } from "store/IAppState";

interface IProps<E extends boolean, T extends IImprovementContext> {
    context?: E extends true ? Context<T> : undefined;
    editable: E;
    createNew?: boolean;
}

const RegistrationStep = <E extends boolean, T extends IImprovementContext>(
    props: PropsWithRef<IProps<E, T>>,
) => {
    const { editable, context, createNew } = props;
    const { t, i18n } = useTranslation();

    const impactGradingsFeature = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.impactGradings === true
        );
    });

    const impactedProcessesFeature = useSelector(
        (appState: IAppState) =>
            (appState.authViewState.profile?.appFeatures?.processChart &&
                appState.authViewState.profile?.appFeatures
                    ?.assignImpactedProcessesToImprovement) === true,
    );
    const {
        impactAreas,
        editValues,
        errors,
        handleOnChange,
        handleOnChangeImpact,
        handleOnChangeImpactedProcess,
    } = useEditable({
        editable,
        context,
        createNew,
        i18n,
        impactedProcessesFeature,
        impactGradingsFeature,
    });

    const severityList = useMemo(
        () => getImpactGradingOptions(i18n),
        [i18n.language],
    );

    const showImpactGradingCard =
        impactGradingsFeature && impactAreas && impactAreas.length > 0;

    const showImpactDropdown =
        !impactGradingsFeature || !impactAreas || impactAreas.length == 0;

    return (
        <div className="improvement-registration-step">
            {showImpactDropdown && (
                <InputComponent
                    wrapperLabel={t(k.IMPACT)}
                    boldLabel
                    required
                    inputType={EActivityType.Dropdown}
                    id="impact"
                    value={String(editValues?.impact ?? "")}
                    options={severityList}
                    invalid={Boolean(errors?.impact)}
                    disabled={!editable}
                    onChange={handleOnChange}
                />
            )}
            <ActionTextField
                label={t(k.DESCRIPTION)}
                boldLabel
                required
                wrapperClassName="activity-instance--card"
                id="deviationMessage"
                testId="deviation-message"
                value={editValues?.deviationMessage ?? ""}
                disabled={!editable}
                invalid={Boolean(errors?.deviationMessage)}
                multiline
                placeholder={t(k.DESCRIBE_IMPOVEMENT_HERE)}
                onChange={handleOnChange}
            />
            {showImpactGradingCard && (
                <ImpactGradingCard
                    impactId="impactList"
                    invalid={
                        Boolean(errors?.impactList) || Boolean(errors?.impact)
                    }
                    required
                    impactList={editValues?.impactList ?? null}
                    impactAreas={impactAreas ?? []}
                    disabled={!editable}
                    onChange={handleOnChangeImpact}
                />
            )}
            {impactedProcessesFeature && (
                <ImpactedProcessesCard
                    errors={errors}
                    impactedProcesses={editValues?.impactedProcesses}
                    disabled={!editable}
                    onChange={handleOnChangeImpactedProcess}
                />
            )}
        </div>
    );
};

export default RegistrationStep;

type notEditableReturnType = {
    impactAreas: IImpactGradingOption[];
    editValues: undefined;
    errors: undefined;
    handleOnChange: undefined;
    handleOnChangeImpact: undefined;
    handleOnChangeImpactedProcess: undefined;
};
type isEditableReturnType = {
    impactAreas: IImpactGradingOption[];
    editValues: IImprovementRequest;
    errors: IValidationMessages;
    handleOnChange: (
        e: IInputActionChangeEvent<string> | IInputChangeEvent<string>,
    ) => void;
    handleOnChangeImpact: (
        list: IDictionary<ImpactGradingEnum>,
        severityMax: ImpactGradingEnum,
    ) => void;
    handleOnChangeImpactedProcess: (
        name: "originated" | "discovered",
        data: IImpactedProcessValue,
    ) => void;
};

const useEditable = <E extends boolean, T extends IImprovementContext>({
    editable,
    context,
    createNew,
    i18n,
    impactedProcessesFeature,
    impactGradingsFeature,
}: IProps<E, T> & {
    i18n: i18n;
    impactedProcessesFeature: boolean;
    impactGradingsFeature: boolean;
}) => {
    const { data: impactAreas } = useImpactGradingOptions(
        i18n.language as LocaleId,
    );

    if (editable !== true || !context) {
        return { impactAreas } as notEditableReturnType;
    }

    const {
        improvement,
        improvementForm,
        editedImprovement,
        setEditedImprovement,
        usersToBeNotified,
        setUsersToBeNotified,
        errors,
        setErrors,
    } = useContext(context);

    const [responsibleUsers, setResponsibleUsers] =
        useState<Array<IValueLabelItem<string, string>>>();

    const editedImprovementRef = useRef(editedImprovement);

    const usersToBeNotifiedMutation =
        useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation();

    const responsibleUsersMutation =
        useGetResponsibleUsersForTemplateByTemplateVersionIdMutation();

    const { data: publishedUsers } = usePublishedUserOptions();

    useEffect(() => {
        editedImprovementRef.current = editedImprovement;

        const fetchData = async (
            mutation: UseMutationResult<
                ExecutionResult<ITemplateVersionConfigurationUsersDto>,
                unknown,
                {
                    request: ITemplateVersionConfigurationUsersRequest;
                },
                unknown
            >,
            shouldSetNotifyUsers = false,
        ) => {
            if (editedImprovement?.formId) {
                let request = {
                    templateId: editedImprovement.formId,
                    impactedProcesses: editedImprovement?.impactedProcesses,
                    isCreate: createNew,
                };

                let data = await mutation.mutateAsync({ request });

                const allUsersArray = mergeStringArraysAndDistinct(
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.discoveredInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.originatedInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    [data.Data?.managerOfReportingUser ?? ""],
                    data.Data?.admins ?? [],
                    data.Data?.deviationManagers ?? [],
                    data.Data?.involvedUserSets?.flatMap((set) => set.users) ??
                        [],
                );

                const selectedUsers = publishedUsers?.options?.filter((user) =>
                    allUsersArray.includes(user.value),
                );

                if (shouldSetNotifyUsers) {
                    setUsersToBeNotified(selectedUsers ?? []);
                } else {
                    setResponsibleUsers(selectedUsers);
                }
                return data;
            }
        };

        // knowing which users are users to be notified
        // users to be notified from the configurations can be from both the responsible and involved users
        // however those involved users can be different than the ones we get from the template configuration
        // but the responsible do not change
        // so filter the responsible notify users, and add to that the edited involved users
        if (editedImprovement) {
            if (
                Object.keys(editedImprovement?.involvedUsers ?? {}).length >
                    0 &&
                responsibleUsers !== undefined &&
                usersToBeNotified !== undefined
            ) {
                const predicate = (value: IImprovementInvolvedUserRequest) =>
                    value?.shouldBeNotified == true;
                let newEditedUsersToBeNotified = filterDictionaryByValue(
                    editedImprovement?.involvedUsers ?? {},
                    predicate,
                );

                let responsibleUsersToBeNotified = usersToBeNotified.filter(
                    (x) =>
                        x.value == responsibleUsers.find((x) => x.value)?.value,
                );

                let totalUsersToBeNotified = Object.keys(
                    newEditedUsersToBeNotified,
                ).concat(responsibleUsersToBeNotified.map((x) => x.value));

                setUsersToBeNotified(
                    publishedUsers?.options?.filter((user) =>
                        totalUsersToBeNotified.includes(user.value),
                    ) ?? [],
                );
            } else {
                if (
                    responsibleUsers === undefined ||
                    usersToBeNotified === undefined
                ) {
                    fetchData(responsibleUsersMutation);
                    fetchData(usersToBeNotifiedMutation, true);
                }
            }
        }
    }, [editedImprovement]);

    const updateImprovementInfoMutatation = useUpdateImprovementMutation();

    const updateImpactedProcesessMutatation =
        useUpdateImpactedProcessMutation();

    const debouncedUpdateImprovementInfoMutatation = useCallback(
        debounce(updateImprovementInfoMutatation.mutate, 1000, {
            leading: false,
            trailing: true,
        }),
        [],
    );

    const debouncedUpdateImpactedProcesessMutatation = useCallback(
        debounce(updateImpactedProcesessMutatation.mutate, 1000, {
            leading: false,
            trailing: true,
        }),
        [],
    );

    const { data: selectedImpactValues } =
        useSelectedImpactValuesByImprovementId(improvement?.id);

    const isEmptyImpactList = impactAreas ? impactAreas.length === 0 : true;

    useEffect(() => {
        if (editedImprovement === undefined) {
            if (improvement && selectedImpactValues) {
                const defaultValues: IImprovementRequest = {
                    formId: improvement.templateId,
                    deviationMessage: improvement.message,

                    impact: improvement.impact,

                    impactList: isEmptyImpactList
                        ? undefined
                        : { ...selectedImpactValues },

                    impactedProcesses: improvement.impactedProcesses ?? {
                        id: v4(),
                        improvementId: improvement.id,
                        discovered: { isNotSure: false },
                        originated: { isNotSure: false },
                    },
                    involvedUsers: improvement.involvedUsers,
                    //isInvolvedUsersChanged: improvement.isInvolvedUsersChanged,
                };

                setEditedImprovement(defaultValues);
            } else if (improvementForm) {
                const defaultValues: IImprovementRequest = {
                    formId: improvementForm.id,
                    deviationMessage: "",

                    impact: ImpactGradingEnum.None,

                    impactList: undefined,

                    impactedProcesses: {
                        id: v4(),
                        improvementId: improvementForm.id,
                        discovered: { isNotSure: false },
                        originated: { isNotSure: false },
                    },
                };

                setEditedImprovement(defaultValues);
            }
        }
    }, [
        Boolean(improvementForm) ||
            (Boolean(improvement) &&
                Boolean(selectedImpactValues) &&
                Boolean(impactAreas)),
        editedImprovement,
    ]);

    const handleChange = async (values: Partial<IImprovementRequest>) => {
        if (editedImprovementRef.current) {
            setEditedImprovement((prev) =>
                prev
                    ? {
                          ...prev,
                          ...values,
                      }
                    : prev,
            );

            if (createNew) {
                await validateChanges(values, setErrors);
            } else {
                try {
                    const newValues = {
                        ...editedImprovementRef.current,
                        ...values,
                    };
                    const savedData: IImprovementUpdateRequest = {
                        id: improvement?.id ?? improvementForm?.id,
                        deviationMessage: newValues.deviationMessage,
                        impact: newValues.impact,
                        impactList: newValues.impactList ?? null,
                        involvedUsers: newValues.involvedUsers,
                        isInvolvedUsersChanged:
                            newValues.isInvolvedUsersChanged,
                    };

                    const validationSchema = createValidationSchema({
                        impactGradingsFeature,

                        isEmptyImpactList,
                    });

                    await validationSchema.validate(savedData, {
                        abortEarly: false,
                    });

                    setErrors({});

                    debouncedUpdateImprovementInfoMutatation(savedData);
                } catch (error) {
                    if (error instanceof yup.ValidationError) {
                        const errorItems = errorToMessageReducer(error);

                        setErrors((prev) => ({ ...prev, ...errorItems }));
                    }
                }
            }
        }
    };

    const handleOnChange = (
        e: IInputActionChangeEvent<string> | IInputChangeEvent<string>,
    ) => {
        const id = e.id;

        if (id) {
            handleChange({ [id]: e.value });
        }
    };

    const handleOnChangeImpact = (
        list: IDictionary<ImpactGradingEnum>,
        severityMax: ImpactGradingEnum,
    ) => {
        handleChange({
            impact: severityMax,

            impactList: list,
        });
    };

    const handleOnChangeImpactedProcess = (
        name: "originated" | "discovered",
        data: IImpactedProcessValue,
    ) => {
        if (editedImprovementRef.current) {
            const isDiscovered = name === "discovered";

            setEditedImprovement?.((prev) =>
                prev && prev.impactedProcesses
                    ? {
                          ...prev,

                          impactedProcesses: {
                              ...prev.impactedProcesses,
                              [name]: { ...data },
                          },
                      }
                    : prev,
            );

            if (createNew) {
                const key = "impactedProcesses." + name;
                validateChanges({ [key]: data }, setErrors);
            } else {
                const request: IImpactedProcessUpdateRequest = {
                    id:
                        editedImprovementRef.current.impactedProcesses?.id ??
                        "",
                    improvementId: improvement?.id ?? improvementForm?.id,
                    isDiscovered,
                    value: data,
                };

                debouncedUpdateImpactedProcesessMutatation(request);
            }
        }
    };

    return {
        impactAreas,
        editValues: editedImprovement ?? improvement,
        errors,
        handleOnChange,
        handleOnChangeImpact,
        handleOnChangeImpactedProcess,
    } as isEditableReturnType;
};

const validateChanges = async (
    values: Partial<IImprovementRequest>,
    setErrors: Dispatch<SetStateAction<IValidationMessages | undefined>>,
) => {
    const keys = Object.keys(values);

    const schemas = yup.object(
        keys.reduce(
            (acc, key) => {
                acc[key] =
                    improvementSchemas[key as keyof typeof improvementSchemas];
                return acc;
            },
            {} as { [key: string]: yup.Schema<any> },
        ),
    );

    try {
        await schemas.validate(values, { abortEarly: false });

        setErrors((prev) => {
            if (!prev) {
                return prev;
            }

            const result = { ...prev };

            keys.forEach((key) => {
                delete result[key];
            });
            return result;
        });
    } catch (error) {
        if (error instanceof yup.ValidationError) {
            const errorItems = errorToMessageReducer(error);

            setErrors((prev) => ({ ...prev, ...errorItems }));
        }
    }
};
