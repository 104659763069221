import "./ImpactedProcessesCard.scss";

import React, { useMemo } from "react";

import { Trans, useTranslation } from "react-i18next";
import { SingleValueProps, components } from "react-select";

import { TFunction } from "i18next";
import { cloneDeep } from "lodash";

import k from "i18n/keys";

import { IImpactedProcessValue } from "../api/IImpactedProcessValue";
import { IImpactedProcessesDto } from "../api/IImpactedProcessesDto";
import DropdownTreeSelect from "common/components/dropdown-tree-select/DropdownTreeSelect";
import { ITreeListOption as IFlatTreeListOption } from "common/components/dropdown-tree-select/DropdownTreeSelect";
import { ArrowRightIcon, InfoIcon } from "common/components/icons";
import InputWrapper from "common/components/input-components/InputWrapper";
import { ESelectedProcessType } from "common/components/input-components/dropdown-tree/ESelectedProcessType";
import ValueWrapper from "common/components/value-wrapper/ValueWrapper";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { useAllProcessChartOptionsTableSet } from "components/process-chart/pages/api/hooks";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

interface IProps {
    errors?: IValidationMessages;
    impactedProcesses?: IImpactedProcessesDto;
    disabled?: boolean;
    onChange?: (
        name: "originated" | "discovered",
        data: IImpactedProcessValue,
    ) => void;
}

const getStyleByIndex = (index: number) => {
    switch (index) {
        case 0:
            return {
                color: "#040930",
                backgroundColor: "#fff",
                borderColor: "#DFE8FC",
            };
        case 1:
            return { color: "#fff", backgroundColor: "#647E9E" };
        default:
            return { color: "#fff", backgroundColor: "#152B6F" };
    }
};

const createSingleValueComponent = (
    t: TFunction,
    data: ITableSetWithOptions<IFlatTreeListOption, string, string>,
) => {
    const SingleValueComponent: React.FC<
        SingleValueProps<IFlatTreeListOption<string, string, any>, false>
    > = (props) => {
        const { data: optionData } = props;

        const pathOptionIds = [...(optionData?.__path ?? []), optionData.value];

        return (
            <components.SingleValue {...props} key={optionData.value}>
                <div className="single-value-option">
                    {pathOptionIds?.map((optionId, index) => {
                        const option = data.values[optionId];
                        const style = !option?.param?.isDeleted
                            ? getStyleByIndex(index)
                            : undefined;
                        return (
                            <React.Fragment key={option?.value}>
                                {index > 0 && <ArrowRightIcon />}
                                <ValueWrapper
                                    customColor={style?.color}
                                    customBackgroundColor={
                                        style?.backgroundColor
                                    }
                                    customBorderColor={style?.borderColor}
                                    isDeleted={option?.param?.isDeleted}
                                    noTitle
                                >
                                    <div className="deleted-option-input">
                                        {option?.label}
                                        {option?.param?.isDeleted && (
                                            <InfoIcon
                                                size="small"
                                                tooltip={{
                                                    id: `${option?.value}-deleted-option-input`,
                                                    message: t(
                                                        k.SELECTED_OPTION_WAS_DELETED,
                                                    ),
                                                }}
                                            />
                                        )}
                                    </div>
                                </ValueWrapper>
                            </React.Fragment>
                        );
                    })}
                </div>
            </components.SingleValue>
        );
    };

    return SingleValueComponent;
};

const ImpactedProcessesCard = (props: IProps) => {
    const { errors, impactedProcesses, disabled } = props;

    const { t } = useTranslation();

    const { data: tableSet } = useAllProcessChartOptionsTableSet<{
        isDeleted: boolean;
    }>({});

    const discoveredData = useMemo(() => {
        if (tableSet) {
            const data = cloneDeep(tableSet);

            if (impactedProcesses?.discovered.isDeleted) {
                const discovered = impactedProcesses.discovered;
                const newOption: (typeof tableSet.values)[string] = {
                    value:
                        discovered.processStepId ??
                        discovered.subProcessId ??
                        discovered.processId ??
                        "",
                    label: discovered.title ?? "<deleted>",
                    param: { isDeleted: true },
                    childIds: [],
                };
                data.values[newOption.value] = newOption;
                data.ids.push(newOption.value);
                data.options.push(newOption.value);
            }

            const otherOption: (typeof tableSet.values)[string] = {
                value: "",
                label: t(k.OTHER_NOT_SURE),
                childIds: [],
            };

            data.values[""] = otherOption;
            data.ids.push("");
            data.options.push("");

            return data;
        }
    }, [tableSet, impactedProcesses?.discovered]);

    const originatedData = useMemo(() => {
        if (tableSet) {
            const data = cloneDeep(tableSet);

            if (impactedProcesses?.originated.isDeleted) {
                const originated = impactedProcesses.originated;
                const newOption: (typeof tableSet.values)[string] = {
                    value:
                        originated.processStepId ??
                        originated.subProcessId ??
                        originated.processId ??
                        "",
                    label: originated.title ?? "<deleted>",
                    param: { isDeleted: true },
                    childIds: [],
                };

                data.values[newOption.value] = newOption;
                data.ids.push(newOption.value);
                data.options.push(newOption.value);
            }

            const otherOption: (typeof tableSet.values)[string] = {
                value: "",
                label: t(k.OTHER_NOT_SURE),
                childIds: [],
            };

            data.values[""] = otherOption;
            data.ids.push("");
            data.options.push("");

            return data;
        }
    }, [tableSet, impactedProcesses?.originated]);

    const SingleValueComponentDiscovered = !!discoveredData
        ? createSingleValueComponent(t, discoveredData)
        : undefined;
    const SingleValueComponentOriginated = !!originatedData
        ? createSingleValueComponent(t, originatedData)
        : undefined;

    const handleChangeDiscovered = (
        selectedValues: IFlatTreeListOption<string, string, any>[],
    ) => {
        handleChange("discovered", selectedValues[0]);
    };

    const handleChangeOriginated = (
        selectedValues: IFlatTreeListOption<string, string, any>[],
    ) => {
        handleChange("originated", selectedValues[0]);
    };

    const handleChange = (
        name: "originated" | "discovered",
        currentNode?: IFlatTreeListOption | null,
    ) => {
        if (props.onChange) {
            if (currentNode?.value) {
                const nodeType = currentNode.__depth;

                switch (nodeType) {
                    case ESelectedProcessType.process:
                        props.onChange(name, {
                            isNotSure: false,
                            processId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.sub_process:
                        props.onChange(name, {
                            isNotSure: false,
                            subProcessId: currentNode.value,
                        });
                        break;

                    case ESelectedProcessType.process_step:
                        props.onChange(name, {
                            isNotSure: false,
                            processStepId: currentNode.value,
                        });
                        break;
                }
            } else {
                props.onChange(name, {
                    isNotSure: true,
                });
            }
        }
    };

    const invalid = Boolean(
        errors?.discovered ||
            errors?.originated ||
            errors?.["impactedProcesses.discovered"] ||
            errors?.["impactedProcesses.originated"],
    );

    const selectedDiscovered = impactedProcesses?.discovered.isNotSure
        ? ""
        : (impactedProcesses?.discovered.processId ??
          impactedProcesses?.discovered.subProcessId ??
          impactedProcesses?.discovered.processStepId);
    const selectedOriginated = impactedProcesses?.originated.isNotSure
        ? ""
        : (impactedProcesses?.originated.processId ??
          impactedProcesses?.originated.subProcessId ??
          impactedProcesses?.originated.processStepId);

    return (
        <>
            <div
                className={
                    invalid
                        ? "activity-instance--card impacted-processes-card impacted-processes-card__invalid"
                        : "activity-instance--card impacted-processes-card"
                }
            >
                <h6 className="activity-instance--title">
                    <strong>{t(k.CHOOSE_IMPACTED_PROCESSES)}</strong>
                </h6>

                <InputWrapper
                    id={"impactedProcesses.discovered"}
                    htmlFor="deviationDiscovered"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.DISCOVERED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    {!!discoveredData && (
                        <DropdownTreeSelect
                            data={discoveredData}
                            inputDataTestId="deviation-discovered-in-process-dropdown-input"
                            onChange={handleChangeDiscovered}
                            disabled={disabled}
                            customOptionLabel={(option) =>
                                option.param && !!option.param.isDeleted ? (
                                    <div className="deleted-option">
                                        {option.label}{" "}
                                        <InfoIcon
                                            size="small"
                                            tooltip={{
                                                id: `${option.value}-deleted-option`,
                                                message: t(
                                                    k.SELECTED_OPTION_WAS_DELETED,
                                                ),
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <>{option.label}</>
                                )
                            }
                            selectedOptionValues={
                                selectedDiscovered !== undefined &&
                                selectedDiscovered !== null
                                    ? [selectedDiscovered]
                                    : []
                            }
                            showPartiallySelected
                            components={{
                                SingleValue: SingleValueComponentDiscovered,
                            }}
                        />
                    )}
                </InputWrapper>

                <InputWrapper
                    id={"impactedProcesses.originated"}
                    htmlFor="deviationOriginated"
                    wrapperLabel={
                        <React.Fragment>
                            <Trans i18nKey={k.ORIGINATED_IN_T} />{" "}
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    }
                >
                    {!!originatedData && (
                        <DropdownTreeSelect
                            data={originatedData}
                            onChange={handleChangeOriginated}
                            inputDataTestId="deviation-originated-in-process-dropdown-input"
                            customOptionLabel={(option) =>
                                option.param?.isDeleted ? (
                                    <div className="deleted-option">
                                        {option.label}
                                        <InfoIcon
                                            size="small"
                                            tooltip={{
                                                id: `${option.value}-deleted-option`,
                                                message: t(
                                                    k.SELECTED_OPTION_WAS_DELETED,
                                                ),
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <>{option.label}</>
                                )
                            }
                            selectedOptionValues={
                                selectedOriginated !== undefined &&
                                selectedOriginated !== null
                                    ? [selectedOriginated]
                                    : []
                            }
                            disabled={disabled}
                            showPartiallySelected
                            components={{
                                SingleValue: SingleValueComponentOriginated,
                            }}
                        />
                    )}
                </InputWrapper>
            </div>
        </>
    );
};

export default ImpactedProcessesCard;
