import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";

import k from "i18n/keys";

import { IMenuNavNodes, navNodeLabelByValue } from "common/IMenuNavNodes";
import { IValueLabelItem } from "common/IValueLabelItem";
import InputWrapper, {
    IInputWrapperProps,
} from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

interface IProps extends IInputWrapperProps {
    id?: string;
    placeholder?: string;
    testId?: string;
    selectedNavNode?: IMenuNavNodes;
    wrapperInputTestId?: string;
    onChange: (navNode: IMenuNavNodes) => void;
}

const MenuNavNodeDropdown: React.FC<IProps> = (props) => {
    const {
        id,
        placeholder,
        className,
        wrapperIcon: icon,
        wrapperLabel: label,
        testId,
        wrapperInputTestId: inputTestId,
        boldLabel,
        selectedNavNode,
        onChange,
    } = props;

    const { t } = useTranslation();

    const navNodeOptions: IValueLabelItem<IMenuNavNodes, string>[] =
        useMemo(() => {
            const navNodeLabels = navNodeLabelByValue(t);
            return Object.values(IMenuNavNodes)
                .filter(
                    (node): node is IMenuNavNodes => typeof node === "number",
                )
                .map((node) => {
                    const navNodeLabel = navNodeLabels[node];
                    return {
                        label: navNodeLabel,
                        value: node,
                    };
                });
        }, [t]);

    const [selectedOption, setSelectedOption] = useState<
        IValueLabelItem<IMenuNavNodes, string> | undefined | null
    >(null);

    useEffect(() => {
        setSelectedOption(
            navNodeOptions.find((option) => option.value === selectedNavNode),
        );
    }, [selectedNavNode]);

    const handleOnChange = (
        option?: IValueLabelItem<IMenuNavNodes, string> | null,
    ) => {
        setSelectedOption(option);
        if (option) {
            onChange(option.value);
        }
    };

    return (
        <InputWrapper
            htmlFor={id}
            className={className}
            wrapperIcon={icon}
            wrapperLabel={label ?? t(k.SHOW_UNDER_NAVIGATION_HEADING)}
            boldLabel={boldLabel}
            wrapperInputTestId={inputTestId}
        >
            <SelectDropdown<
                IValueLabelItem<IMenuNavNodes, string>,
                false,
                GroupBase<IValueLabelItem<IMenuNavNodes, string>>
            >
                inputId={id}
                value={selectedOption}
                isSearchable
                placeholder={placeholder ?? t(k.SELECT)}
                options={navNodeOptions}
                testId={testId}
                closeMenuOnSelect
                onChange={handleOnChange}
            />
        </InputWrapper>
    );
};

export default MenuNavNodeDropdown;
