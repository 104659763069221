import {
    HIDE_IMAGE_PREVIEW,
    ImagesActionTypes,
    SHOW_BLOB_IMAGE_PREVIEW,
    SHOW_IMAGE_PREVIEW,
    SHOW_IMAGE_PREVIEW_BY_ID,
} from "./types";
import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";

export const showBlobImage = (value: IBlobSrcType): ImagesActionTypes => {
    return {
        type: SHOW_BLOB_IMAGE_PREVIEW,
        payload: value,
    };
};

export const showImage = (value: string): ImagesActionTypes => {
    return {
        type: SHOW_IMAGE_PREVIEW,
        payload: value,
    };
};

export const showImageById = (imgId: string): ImagesActionTypes => {
    return {
        type: SHOW_IMAGE_PREVIEW_BY_ID,
        payload: imgId,
    };
};

export const hideImage = (): ImagesActionTypes => {
    return {
        type: HIDE_IMAGE_PREVIEW,
    };
};
