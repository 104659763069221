import React from "react";

import axios from "axios";
import i18n from "i18next";

import k from "i18n/keys";

import { IProcessChartTableSetOptionDTO } from "./IProcessChartTableSetOptionDTO";
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { ITreeListOption } from "common/ITreeListOption";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import { IValueLabelWithRelation } from "common/IValueLabelWithRelation";
import {
    IInitialFetchOptions,
    useInitialFetch,
} from "common/utils/query/useInitialFetch";
import { IImpactedProcessValue } from "components/improvements/api/IImpactedProcessValue";
import { ProcessChartImprovementType } from "components/process-chart/api/improvements/ProcessChartImprovementType";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

const baseUrl = "/api/processchart";

async function getSelectedProcess(signal?: AbortSignal) {
    const url = `${baseUrl}/selected-process`;

    const response = await axios.get<IValueLabelItem<string, string>>(url, {
        signal,
    });

    return response.data;
}

export const useSelectedProcess = (enabled: boolean = true) => {
    return useQuery({
        queryKey: ["manage-process-chart--selected-process"],

        queryFn: ({ signal }) => getSelectedProcess(signal),

        enabled,
    });
};

async function saveProcess(id?: string) {
    const url = `${baseUrl}/selected-process`;

    const data = new FormData();
    data.append("id", id ?? "");

    await axios.post(url, data);
}

export const useMutateProcess = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: saveProcess,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["manage-process-chart--selected-process"],
            });
        },
    });
};

export const useProcessChartItemNames = (
    request:
        | Partial<
              Record<
                  | ProcessChartImprovementType.Process
                  | ProcessChartImprovementType.SubProcess
                  | ProcessChartImprovementType.ProcessStep,
                  string[]
              >
          >
        | undefined,
) => {
    return useQuery({
        queryKey: ["process-chart-item-names", request],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/item-names`;

            const response = await axios.post<
                Record<string, IValueLabelItemWithState<string> | undefined>
            >(url, request, {
                signal,
            });

            return response.data;
        },

        enabled: Boolean(request),
        placeholderData: keepPreviousData,
    });
};

export const useProcessOptions = (enabled?: boolean) => {
    return useQuery({
        queryKey: ["process-chart--process-options"],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/process-options`;

            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                { signal },
            );

            return response.data;
        },

        enabled: enabled ?? true,
    });
};

export const useAllProcessChartOptions = (enabled = true) => {
    return useQuery({
        queryKey: ["all-process-chart-options"],

        queryFn: async ({ signal }) => {
            const url = `${baseUrl}/all-options-tree`;

            const response = await axios.get<ITreeListOption[]>(url, {
                signal,
            });

            return response.data;
        },

        enabled,
    });
};

export const useAllProcessChartOptionsTableSet = <T>({
    enabled,
    syncData,
}: IInitialFetchOptions) =>
    useInitialFetch(
        ["all-process-chart-options-table-set"],
        async ({ signal }) => {
            const url = `${baseUrl}/all-options-tree-table-set`;

            const response = await axios.get<
                ITableSetWithOptions<
                    IProcessChartTableSetOptionDTO<T>,
                    string,
                    string
                >
            >(url, {
                signal,
            });

            return response.data;
        },
        { enabled, syncData },
    );
