import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";

export interface IMenuOption<T = undefined>
    extends IValueLabelItem<string, string, T> {
    noTranslation?: boolean;
    linkTo?: string;
}

export const CustomListItemsPaths: string[] = ["/custom-list-item"];
export const ProcessChartPaths: string[] = [
    "/processchart",
    "/process",
    "/subprocess",
];

export const PagesPaths: string[] = ["/pages", "/page"];

export const ReportsMenuOptions: Array<
    IMenuOption<
        "checklistsReport" | "improvementDashboard" | "checklists-report-search"
    >
> = [
    {
        value: "/checklists-report",
        label: k.NAV_NODE_CHECKLISTS_REPORT,
        param: "checklistsReport",
    },
    {
        value: "/improvement-dashboard",
        label: k.NAV_NODE_IMPROVEMENT_DASHBOARD,
        param: "improvementDashboard",
    },
    {
        value: "/checklists-search",
        label: k.NAV_NODE_CHECKLISTS_SEARCH,
        param: "checklists-report-search",
    },
];

export const TemplateMenuOptions: Array<
    IMenuOption<"sharedTemplateSteps" | "improvementForms">
> = [
    {
        value: "/templates",
        label: k.NAV_NODE_CHECKLIST_TEMPLATE,
    },
    {
        value: "/improvement-forms",
        label: k.NAV_NODE_IMPROVEMENT_FORMS,
        param: "improvementForms",
    },
    {
        value: "/steps",
        label: k.NAV_NODE_SHARED_TEMPLATE_STEPS,
        param: "sharedTemplateSteps",
    },
];

export const PerformanceManagementMenuOptions: Array<
    IMenuOption<"kpi" | "goals">
> = [
    {
        value: "/kpi",
        label: k.NAV_NODE_KPI,
        param: "kpi",
    },
    {
        value: "/goals",
        label: k.NAV_NODE_GOALS,
        param: "goals",
    },
];

export const SeniorManagementMenuOptions: Array<
    IMenuOption<"stakeholders" | "legislations" | "impactGradings">
> = [
    {
        value: "/stakeholders",
        label: k.NAV_NODE_STAKEHOLDERS,
        param: "stakeholders",
    },
    {
        value: "/legislations",
        label: k.LEGISLATION_PAGE_TITLE,
        param: "legislations",
    },
    {
        value: "/impact-gradings",
        label: k.NAV_NODE_IMPACT_GRADINGS,
        param: "impactGradings",
    },
];

export const MarketingSalesMenuOptions: Array<
    IMenuOption<"customers" | "customerRequirements">
> = [
    {
        value: "/customers",
        label: k.NAV_NODE_CUSTOMERS,
        param: "customers",
    },
    {
        value: "/customer-requirements",
        label: k.CUSTOMER_REQUIREMENT_PAGE_TITLE,
        param: "customerRequirements",
    },
];

export const SourcingPurchaseMenuOptions: Array<
    IMenuOption<"suppliers" | "purchaseOrders" | "itemCatalog">
> = [
    {
        value: "/suppliers",
        label: k.NAV_NODE_SUPPLIERS,
        param: "suppliers",
    },
    {
        value: "/purchase-orders",
        label: k.NAV_NODE_PURCHASE_ORDERS,
        param: "purchaseOrders",
    },
    {
        value: "/items",
        label: k.NAV_NODE_ITEMS,
        param: "itemCatalog",
    },
];

export const HRMenuOptions: Array<
    IMenuOption<
        | "users"
        | "positions"
        | "competencies"
        | "teams"
        | "competencyMatrix"
        | "staffAppraisals"
        | "trainingPlans"
    >
> = [
    {
        value: "/users",
        label: k.NAV_NODE_USERS,
        param: "users",
    },
    {
        value: "/positions",
        label: k.NAV_NODE_POSITIONS,
        param: "positions",
    },
    {
        value: "/teams",
        label: k.NAV_NODE_TEAMS,
        param: "teams",
    },
    {
        value: "/competencies",
        label: k.COMPETENCY_PAGE_TITLE,
        param: "competencies",
    },
    {
        value: "/competency-matrix",
        label: k.NAV_NODE_COMPETENCY_MATRIX,
        param: "competencyMatrix",
    },
    {
        value: "/staff-appraisals",
        label: k.NAV_NODE_STAFF_APPRAISAL,
        param: "staffAppraisals",
    },
    {
        value: "/training-plans",
        label: k.NAV_NODE_TRAINING_PLAN,
        param: "trainingPlans",
    },
];

export const OtherResourcesMenuOptions: Array<IMenuOption<"equipment">> = [
    {
        value: "/equipment",
        label: k.NAV_NODE_EQUIPMENT,
        param: "equipment",
    },
];

export const RiskManagementMenuOptions: Array<
    IMenuOption<"operationalRiskAnalysis" | "environmentalAspects">
> = [
    {
        value: "/operational-risk-analysis",
        label: k.NAV_NODE_OPERATIONAL_RISK_ANALYSIS,
        param: "operationalRiskAnalysis",
    },
    {
        value: "/environmental-aspects",
        label: k.NAV_NODE_ENVIRONMENTAL_ASPECTS,
        param: "environmentalAspects",
    },
];

export const AdministratorMenuOptions: Array<
    IMenuOption<
        | "itemCatalog"
        | "purchaseOrders"
        | "processChart"
        | "customerIndustries"
        | "webhooks"
        | "sso"
        | "tenantSettings"
    >
> = [
    {
        value: "/tenant-settings/general",
        label: k.TENANT_SETTINGS,
        param: "tenantSettings",
    },
];
