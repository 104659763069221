import "./MeasurementChart.scss";

import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { useProgressListByMeasurementId } from "../measurement-progress-value/api/hooks";
import {
    EDisplayProgressType,
    getProgressByYearAndMonth,
    getProgressByYearAndQuarter,
    getProgressLineData,
    progressTypeOptions,
    progressTypes,
} from "../utils/MeasurementTools";
import { IValueLabelItem } from "common/IValueLabelItem";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { lazyMinLoadTime } from "common/utils/lazyMinLoadTime";
import InfoCard from "components/goals-page/components/InfoCard";
import LazyLoadingFallback from "routes/components/LazyLoadingFallback";

const Plot = lazyMinLoadTime(() => import("common/components/plotly"));

interface IMeasurementChartProps {
    isProgressDirectionUp: boolean;
    measurementId: string;
    emptyContent?: React.ReactNode;
    endDate?: Date;
    startDate?: Date;
}

export type DataPerYear = {
    x: string[];
    y: number[];
};

const GREEN_COLOR = "#0DB691";
const YELLOW_COLOR = "#FFC107";
const RED_COLOR = "#ff2929";
const COUNT_OF_MONTHS = 12;

const MeasurementChart = (props: IMeasurementChartProps) => {
    const { measurementId, isProgressDirectionUp, startDate, endDate } = props;
    const { data: values, isLoading: listLoading } =
        useProgressListByMeasurementId(measurementId);

    const { t, i18n } = useTranslation();

    const [selectedOption, setSelectedOption] = useState<
        IValueLabelItem<number, string>
    >({
        label: t(progressTypes[EDisplayProgressType.Monthly]),
        value: EDisplayProgressType.Monthly,
    });

    const preparedData = useMemo(() => {
        return getProgressLineData(
            selectedOption.value === EDisplayProgressType.Monthly
                ? getProgressByYearAndMonth(values ?? [], startDate, endDate)
                : getProgressByYearAndQuarter(values ?? [], startDate, endDate),
            t,
        );
    }, [values, selectedOption, startDate, endDate]);

    const dropdownOptions = progressTypeOptions(i18n);

    const handleOnChangeStatus = (e: any) => {
        const option = e as IValueLabelItem<number, string>;

        setSelectedOption(option);
    };

    const getMarkers = (
        preparedData: DataPerYear,
        isSecondary?: boolean,
        name?: string,
        year?: number,
        group?: string,
    ) => {
        const x = preparedData.x;
        const y = preparedData.y;
        const markerColors = preparedData.y.map((value, i) => {
            if (i === 0) {
                return YELLOW_COLOR;
            }

            if (isProgressDirectionUp) {
                return value > preparedData.y[i - 1]
                    ? GREEN_COLOR
                    : value === preparedData.y[i - 1]
                      ? YELLOW_COLOR
                      : RED_COLOR;
            }
            return value < preparedData.y[i - 1]
                ? GREEN_COLOR
                : value === preparedData.y[i - 1]
                  ? YELLOW_COLOR
                  : RED_COLOR;
        });

        const markersPlot: Plotly.Data = {
            x,
            y,
            type: "scatter",
            mode: "markers",
            marker: {
                color: markerColors,
                size: COUNT_OF_MONTHS,
            },
            name: name ?? "",
            legendgroup: group ?? "markerGroup " + year,
            showlegend: false,
        };

        const markersLegend: Plotly.Data = {
            x: [null],
            y: [null],
            type: "scatter",
            mode: "markers",
            marker: {
                color: YELLOW_COLOR,
                size: COUNT_OF_MONTHS,
            },
            name: name ?? "",
            legendgroup: group ?? "markerGroup " + year,
            showlegend: !!name,
        };

        return { markersPlot, markersLegend };
    };

    const getProgressLine = (
        x: string[],
        y: number[],
        name?: string,
        group?: string,
    ) => {
        const line: Plotly.Data = {
            x,
            y,
            legendgroup: group,
            type: "scatter",
            mode: "lines",
            line: {
                color: "#152b6f",
                width: 1,
            },
            name: name ?? "",
            showlegend: !!name,
        };

        return line;
    };

    const data = useMemo(() => {
        const progressLine = getProgressLine(
            preparedData.x,
            preparedData.y,
            t(k.PROGRESS),
        );

        const { markersPlot, markersLegend } = getMarkers(
            preparedData,
            false,
            t(k.MARKERS_OF_PROGRESS_LINE),
        );

        return [progressLine, markersPlot, markersLegend];
    }, [preparedData]);

    const layout: Partial<Plotly.Layout> = {
        legend: { orientation: "h", tracegroupgap: 4 },
        hoverlabel: {
            align: "left",
            bgcolor: "#fff",
        },
        xaxis: {
            showgrid: false,
            zeroline: false,
            showline: true,
            mirror: "ticks",
            side: "top",
        },
        yaxis: {
            showgrid: false,
            zeroline: false,
            showline: true,
            mirror: "ticks",
        },
        dragmode: "pan",
    };

    const config = { displayModeBar: false, responsive: true };

    return (
        <InfoCard
            className="measurement-chart"
            label={t(k.PROGRESS_OVER_TIME)}
            rightSideLabelContent={
                <div className="dropdown-container">
                    <SelectDropdown
                        options={dropdownOptions}
                        value={selectedOption}
                        onChange={handleOnChangeStatus}
                    />
                </div>
            }
        >
            {values && values.length > 0 ? (
                <div className="measurement-chart--plot">
                    <React.Suspense fallback={<LazyLoadingFallback />}>
                        <Plot data={data} layout={layout} config={config} />
                    </React.Suspense>
                </div>
            ) : (
                props?.emptyContent
            )}
        </InfoCard>
    );
};

export default MeasurementChart;
