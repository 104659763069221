import "./SubmitImprovementCard.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import Button from "../buttons/Button";
import InvalidIcon from "./assets/invalid.svg";
import SubmitImg from "./assets/submit.png";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    invalid: boolean;
    invalidActivities: Array<IValueLabelItem<number, string, number>>;
    isSaving: boolean;
    usersToBeNotified?: Array<IValueLabelItem<string, string>>;

    onClick: () => void;
}

const SubmitImprovementCard: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { invalid, invalidActivities, isSaving, usersToBeNotified } = props;

    const { t } = useTranslation();

    const isSubmitDisabled = isSaving;

    const [submitNoticeKey, setSubmitNoticeKey] = useState(k.SUBMIT_NOTICE);

    useEffect(() => {
        const submitNoticeKey =
            usersToBeNotified && usersToBeNotified.length > 0
                ? k.SUBMIT_NOTICE_WITH_USER_TO_NOTIFY
                : k.SUBMIT_NOTICE;
        setSubmitNoticeKey(submitNoticeKey);
    }, [usersToBeNotified]);

    if (invalid) {
        return (
            <div className="submit-card__wrapper submit-card--invalid">
                <div className="img">
                    <InvalidIcon />
                </div>

                <div className="desc">
                    <div>
                        {t(
                            k.IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED,
                        )}
                    </div>

                    <br />
                    {invalidActivities.length === 1 ? (
                        <>
                            {t(k.COMPLETE_THE)}{" "}
                            <span className="invalid">
                                {t(k.ACTIVITY)} {invalidActivities[0].value}:{" "}
                                {invalidActivities[0].label}
                            </span>
                        </>
                    ) : (
                        invalidActivities.length > 1 && (
                            <>
                                {t(k.COMPLETE_THE_ACTIVITIES)}:
                                <ul className="invalid">
                                    {invalidActivities.map((x, i) => (
                                        <li key={i}>
                                            {x.param && <>{x.param}.</>}
                                            {x.value}. {x.label}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )
                    )}
                </div>

                <Button
                    disabled={isSubmitDisabled}
                    isBusy={isSubmitDisabled}
                    testId="submitCardBtn"
                    onClick={props.onClick}
                    variant="success"
                    size="small"
                >
                    {t(k.SUBMIT_IMPOVEMENT)}
                </Button>
            </div>
        );
    }

    const labelElements =
        usersToBeNotified &&
        usersToBeNotified.map((user, index) => {
            const label = <strong key={index}>{user.label}</strong>;
            if (index === usersToBeNotified.length - 1 && index !== 0) {
                return (
                    <React.Fragment key={index}>
                        {" "}
                        {t(k.AND_)} {label}
                    </React.Fragment>
                );
            } else if (index === 0) {
                return label;
            } else {
                return <React.Fragment key={index}>, {label}</React.Fragment>;
            }
        });

    return (
        <div className="submit-card__wrapper">
            <div className="img">
                <img className="img-icon" src={SubmitImg} alt="" />
            </div>

            <div className="desc ">
                <h3 className="title">{t(k.DONE)}?</h3>
                {t(submitNoticeKey)} {labelElements}
            </div>

            <Button
                disabled={isSubmitDisabled}
                isBusy={isSubmitDisabled}
                testId="submitCardBtn"
                onClick={props.onClick}
                variant="success"
                size="small"
            >
                {t(k.SUBMIT_IMPOVEMENT)}
            </Button>
        </div>
    );
};

export default SubmitImprovementCard;
