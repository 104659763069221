import "./Layout.scss";

import React, { useEffect } from "react";

import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isOnTestAmpliflowHost } from "config";

import MenuSection, { IMenuSectionProps } from "./MenuSection";
import TeamsAppLayout from "./TeamsAppLayout";
import { useMsal } from "@azure/msal-react";
import { useQueryClient } from "@tanstack/react-query";
import SwitchLocale from "i18n/SwitchLocale";
import { IAppState } from "store/IAppState";
import { logout } from "store/auth/actions";
import { updateMenuSettings } from "store/uiSettings/actions";
import { ELayoutEnvironment } from "store/uiSettings/reducer";

const LayoutWithMenu: React.FC<React.PropsWithChildren<IMenuSectionProps>> = (
    props,
) => {
    return (
        <>
            <MenuSection {...props} />
            <div className="main">{props.children}</div>
        </>
    );
};

interface IStateProps
    extends Pick<
        IMenuSectionProps,
        "isMenuExpandedWhenPageLoads" | "profile" | "roles"
    > {
    layoutEnvironment: ELayoutEnvironment;
}

interface IDispatchProps {
    logout: () => void;
    updateMenuSettings: (isExpanded: boolean) => void;
}

type Props = IStateProps & IDispatchProps;

const devHosts: string[] = ["localhost", "af.pbjorklund.com"];

const LayoutWithNavigation: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { instance } = useMsal();

    const layoutEnvironment = useSelector((appState: IAppState) => {
        const { layoutEnvironment } = appState.uiSettingsViewState;

        return layoutEnvironment;
    });

    useEffect(() => {
        let accountPrefix = "";

        if (isOnTestAmpliflowHost()) {
            accountPrefix = "test-";
        } else if (devHosts.some((x) => window.location.host.indexOf(x) > -1)) {
            accountPrefix = "dev-";
        }

        pendo.initialize({
            visitor: {
                id: String(props.profile?.id),
                email: String(props.profile?.email),
                full_name: String(props.profile?.username),
                role: props.profile?.roles.map((x) => x.label) ?? [], // Optional
                localeId: String(props.profile?.localeId),
                availableFeatures:
                    props.profile?.roles.map((x) => x.label) ?? [],
            },

            account: {
                id: `${accountPrefix}${String(props.profile?.tenantId)}`,
                name: window.location.host.split(".")[1]
                    ? window.location.host.split(".")[0]
                    : "unknown",
            },
        });
    }, [props.profile]);

    const handleClickLogout = () => {
        if (layoutEnvironment !== ELayoutEnvironment.TEAMS) {
            instance.logoutRedirect({
                onRedirectNavigate: (url: string) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                },
            });
        }
        props.logout();
        queryClient.clear();
    };

    return (
        <React.Fragment>
            {props.layoutEnvironment === ELayoutEnvironment.BROWSER && (
                <LayoutWithMenu
                    isMenuExpandedWhenPageLoads={
                        props.isMenuExpandedWhenPageLoads
                    }
                    profile={props.profile}
                    roles={props.roles}
                    onNavigateTo={navigate}
                    onSignOut={handleClickLogout}
                    updateMenuSettings={props.updateMenuSettings}
                >
                    {props.children}
                </LayoutWithMenu>
            )}

            {props.layoutEnvironment === ELayoutEnvironment.TEAMS && (
                <TeamsAppLayout
                    localeSwitch={<SwitchLocale onlyIcon />}
                    profile={props.profile}
                    onSignOut={handleClickLogout}
                >
                    {props.children}
                </TeamsAppLayout>
            )}
        </React.Fragment>
    );
};

const mapToProps = (appState: IAppState): IStateProps => {
    const { profile, roles } = appState.authViewState;
    const { isMenuExpanded, layoutEnvironment } = appState.uiSettingsViewState;

    return {
        profile,
        roles,
        isMenuExpandedWhenPageLoads: isMenuExpanded,
        layoutEnvironment,
    };
};

const actions: IDispatchProps = {
    logout,
    updateMenuSettings,
};

export default connect(mapToProps, actions)(LayoutWithNavigation);
