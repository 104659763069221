import "./Avatar.scss";

import React, { useMemo } from "react";
import AvatarComponent from "react-avatar";
import { v4 as uuidv4 } from "uuid";

import Tooltip, { TooltipPlacement } from "../tooltip/Tooltip";

const defaultSize = 24;
const minRatio = 1.75;
interface IProps {
    name?: string;

    className?: string;

    tooltipMessage?: React.ReactNode;
    tooltipPlacement?: TooltipPlacement;

    size?: number;

    initials?: string;

    maxInitials?: number;

    textSizeRatio?: number;
    color?: string;
    onClick?: () => void;
}

const Avatar: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        name,
        className,
        tooltipMessage,
        tooltipPlacement,
        size = defaultSize,
        initials,
        maxInitials,
        color,
    } = props;

    const tooltipId = useMemo(() => uuidv4(), []);

    if (!name) {
        return null;
    }

    const textSizeRatio =
        props.textSizeRatio ?? size > defaultSize
            ? minRatio + Math.log(size / defaultSize)
            : minRatio;

    if (tooltipMessage) {
        return (
            <Tooltip
                id={`avatar-${tooltipId}`}
                message={tooltipMessage}
                placement={tooltipPlacement}
            >
                <AvatarComponent
                    round={true}
                    size={size + "px"}
                    name={name}
                    className={className}
                    title=" "
                    initials={initials}
                    maxInitials={maxInitials}
                    textSizeRatio={textSizeRatio}
                    color={color}
                    onClick={props.onClick}
                />
            </Tooltip>
        );
    }

    return (
        <AvatarComponent
            round={true}
            size={size + "px"}
            name={name}
            className={className}
            initials={initials}
            maxInitials={maxInitials}
            textSizeRatio={textSizeRatio}
            color={color}
            onClick={props.onClick}
        />
    );
};

export default Avatar;
