import { LocaleId } from "AppLocale";
import axios from "axios";

import IImpactGradingFilter from "./IImpactGradingFilter";
import { IImpactGradingOption } from "./IImpactGradingOption";
import IImpactGradingRowDTO from "./IImpactGradingRowDTO";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ExecutionResult from "common/viewModels/ExecutionResult";
import ITableSet from "http/ITableSet";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const baseUrl = "/api/impact-grading";

const impactGradingKeys = {
    tableSet: (filter?: IImpactGradingFilter) =>
        ["impact-grading-table-set", filter] as const,
    options: (localeId?: LocaleId) =>
        ["impact-grading-published-options", localeId] as const,
};

export const useImpactGradingTableSet = (filter?: IImpactGradingFilter) => {
    const url = `${baseUrl}/table-set`;

    return useQuery({
        queryKey: impactGradingKeys.tableSet(filter),

        queryFn: async (context) => {
            const response = await axios.post<ITableSet<IImpactGradingRowDTO>>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },

        enabled: !!filter,
    });
};

export const useImpactGradingOptions = (
    localeId?: LocaleId,
    enabled: boolean = true,
) => {
    const url = `${baseUrl}/options`;

    return useQuery({
        queryKey: impactGradingKeys.options(localeId),

        queryFn: async (context) => {
            const response = await axios.get<IImpactGradingOption[]>(url, {
                signal: context.signal,
                params: { localeId },
            });

            return response.data;
        },

        enabled: !!localeId && enabled,
    });
};

export const useDeleteImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { id: string }) =>
            axios.delete(`${baseUrl}/${variables.id}`),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [impactGradingKeys.tableSet()[0]],
            });
        },
    });
};

export const useArchiveImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [impactGradingKeys.tableSet()[0]],
            });
        },
    });
};

export const useSaveImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IImpactGradingRowDTO;
        }) => {
            let result: string;
            const { isCreate, value } = variables;

            if (isCreate) {
                const response = await axios.post<string>(baseUrl, value);

                result = response.data;
            } else {
                const response = await axios.put<string>(baseUrl, value);

                result = response.data;
            }

            return result;
        },

        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [impactGradingKeys.tableSet()[0]],
            });
        },
    });
};

export async function importImpactGradingMutation(
    data: IImpactGradingRowDTO[],
) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
