import "./MeasurementProgressForm.scss";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

import k from "i18n/keys";

import { useMeasurementAccessRole } from "../api/hooks";
import { IMeasurementProgressValueDTO } from "../measurement-progress-value/api/IMeasurementProgressValueDTO";
import { decomposeMeasurementAccessRole } from "../utils/MeasurementTools";
import {
    useArchiveMeasurementProgressValueMutation,
    useDeleteMeasurementProgressValueMutation,
    useMeasurementProgressValue,
    useSaveMeasurementProgressValueMutation,
} from "./api/hooks";
import { ButtonGroup } from "common/components/buttons";
import Button from "common/components/buttons/Button";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import ModalContent from "common/components/modal-content/ModalContent";
import { errorToMessageReducer } from "common/components/validation/yupValidationErrorUtili";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import InfoCard from "components/goals-page/components/InfoCard";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import HistoryButton from "components/history/components/HistoryButton";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    id?: string;
    isChanged: boolean;
    createNew?: boolean;
    measurementId?: string;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
    onSave?: (id: string) => void;
}

const formSchema = yup.object().shape({
    value: yup.number().typeError("Value must be a number"),
    absoluteValue: yup.number().typeError("Value must be a number"),
});

const MeasurementProgressForm = (props: IProps) => {
    const { createNew } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const canAccessGoals = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR ||
                roles.GOALS_OWNER ||
                roles.GOALS_EDITOR ||
                roles.GOALS_USER) ??
            false
        );
    });

    const canDelete = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (roles.ADMINISTRATOR || roles.GOALS_OWNER) ?? false;
    });

    const { data: measurementAccessRole } = useMeasurementAccessRole(
        canAccessGoals ? props.measurementId : undefined,
    );

    const { canEditInPreview: canEditProgress } =
        decomposeMeasurementAccessRole(measurementAccessRole);

    const saveMutation = useSaveMeasurementProgressValueMutation();
    const archiveMutation = useArchiveMeasurementProgressValueMutation();
    const deleteMutation = useDeleteMeasurementProgressValueMutation();

    const [errors, setErrors] = useState<IValidationMessages>();

    const [progressValue, setProgressValue] =
        useState<IMeasurementProgressValueDTO>({
            id: uuidv4(),
            value: 0,
            comment: "",
            measurementId: props.measurementId,
            date: new Date(Date.now()),
        });

    const [progressValueCalculations, setProgressValueCalculations] = useState<{
        value: string;
        absoluteValue: string;
    }>({
        value: "0",
        absoluteValue: "0",
    });

    const { data: progressValueData, refetch } = useMeasurementProgressValue(
        {
            measurementProgressId: props.id,
            measurementId: props.measurementId,
            date: progressValue.date,
        },
        canEditProgress,
    );

    useEffect(() => {
        if (progressValue.date) {
            refetch();
        }
    }, [progressValue.date]);

    useEffect(() => {
        if (progressValueData) {
            if (!props.id) {
                setProgressValue((prev) => {
                    return {
                        ...prev,
                        targetValue: progressValueData.targetValue,
                        value: progressValueData.value,
                        calculatedValue: progressValueData.calculatedValue,
                    };
                });
            } else {
                setProgressValue(progressValueData);
            }
            setProgressValueCalculations({
                value: progressValueData.value?.toString(),
                absoluteValue: (
                    progressValueData.value +
                    (progressValueData.calculatedValue ?? 0)
                ).toString(),
            });
        }
    }, [progressValueData]);

    const handleSave = async () => {
        try {
            const request = {
                ...progressValue,
                value: parseFloat(progressValueCalculations.value),
                absoluteValue: parseFloat(
                    progressValueCalculations.absoluteValue,
                ),
            };
            await formSchema.validate(request, { abortEarly: false });
            await saveMutation.mutateAsync(
                {
                    isCreate: !!createNew,
                    value: request,
                },
                {
                    onSuccess: () => {
                        props.onClose(true);
                        setErrors({});
                    },
                },
            );
        } catch (err: any) {
            if (err instanceof yup.ValidationError) {
                const errorItems = errorToMessageReducer(err);

                setErrors((prev) => ({
                    ...prev,
                    ...errorItems,
                }));

                toast.error(errorItems.value ?? errorItems.absoluteValue, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const handleCancel = () => {
        props.onClose(false);
    };

    const handleOnChangeIncremetalProgress = (
        event: IInputChangeEvent<string>,
    ) => {
        const value = parseFloat(event.value);

        setProgressValueCalculations((prev) => ({
            ...prev,
            value: event.value,
            absoluteValue: isNaN(value)
                ? prev.absoluteValue
                : ((progressValue.calculatedValue ?? 0) + value).toString(),
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeAbsoluteProgress = (
        event: IInputChangeEvent<string>,
    ) => {
        const value = parseFloat(event.value);

        setProgressValueCalculations((prev) => ({
            ...prev,
            value: isNaN(value)
                ? prev.value
                : (value - (progressValue.calculatedValue ?? 0)).toString(),
            absoluteValue: event.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeDate = (event: IInputChangeEvent<Date | undefined>) => {
        const date = event.value;

        setProgressValue((prev) => ({
            ...prev,
            date,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeComment = (
        event: IInputChangeEvent<string | undefined>,
    ) => {
        const comment = event.value;

        setProgressValue((prev) => ({
            ...prev,
            comment,
        }));
        props.onHaveChanges(true);
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const handleArchive = async () => {
        if (progressValue.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: progressValue.isArchived ? "blue" : "danger",
                yesLabel: progressValue.isArchived
                    ? t(k.UN_ARCHIVE)
                    : t(k.ARCHIVE),
                title: progressValue.isArchived
                    ? t(k.UNARCHIVE_THIS_PROGRESS_VALUE)
                    : t(k.ARCHIVE_THIS_PROGRESS_VALUE),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                await archiveMutation.mutateAsync({
                    id: progressValue.id,
                    isArchive: !progressValue.isArchived,
                });
            }
        }
    };

    const handleDelete = async () => {
        if (progressValue.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
                title: t(k.DELETE_THIS_PROGRESS_VALUE),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                await deleteMutation.mutateAsync(progressValue.id, {
                    onSuccess: () => {
                        props.onClose(true);
                        toast.success(t(k.PROGRESS_VALUE_DELETED), {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    },
                });
            }
        }
    };

    return (
        <ModalContent>
            <ModalContent.Header
                title={createNew ? t(k.ADD_PROGRESS) : t(k.UPDATE_PROGRESS)}
                onClose={handleOnClose}
                actions={
                    !createNew &&
                    progressValue.id && (
                        <HistoryButton
                            id={progressValue.id}
                            type={HistoryOwnerEnum.MeasurementProgress}
                        />
                    )
                }
            />
            <ModalContent.Body className="measurement-progress-form--body">
                <InfoCard
                    label={t(k.UPDATE_VALUE)}
                    childrenClassName="update-value-card"
                >
                    <div className="update-value-card--row">
                        <InputWrapper
                            wrapperLabel={t(k.TARGET_VALUE)}
                            fullWidth
                            noMinWidth
                            boldLabel
                        >
                            <div
                                className="value--preview"
                                data-testid="measurement_progress_target_value_input"
                            >
                                {progressValue?.targetValue ?? 0}
                            </div>
                        </InputWrapper>

                        <InputWrapper
                            wrapperLabel={t(k.CURRENT_VALUE_AT_DATE)}
                            fullWidth
                            noMinWidth
                            boldLabel
                        >
                            <div
                                className="value--preview"
                                data-testid="measurement_progress_current_value_input"
                            >
                                {progressValue?.calculatedValue ?? 0}
                            </div>
                        </InputWrapper>
                        <ActionTextField
                            noMinWidth
                            fullWidth
                            label={t(k.ADD_INCREMENTAL_PROGRESS)}
                            id={`${progressValue.id}_increment_value_input`}
                            disabled={progressValue.isArchived}
                            boldLabel
                            inputFieldType="number"
                            testId="measurement_progress_increment_value_input"
                            value={progressValueCalculations.value}
                            invalid={!!errors?.value}
                            placeholder={t(k.ENTER_HERE)}
                            onChange={handleOnChangeIncremetalProgress}
                        />
                        <ActionTextField
                            noMinWidth
                            fullWidth
                            label={t(k.ADD_ABSOLUTE_PROGRESS)}
                            id={`${progressValue.id}_absolute_value_input`}
                            disabled={progressValue.isArchived}
                            boldLabel
                            inputFieldType="number"
                            testId="measurement_progress_absolute_value_input"
                            value={progressValueCalculations.absoluteValue}
                            invalid={!!errors?.absoluteValue}
                            placeholder={t(k.ENTER_HERE)}
                            onChange={handleOnChangeAbsoluteProgress}
                        />
                    </div>
                    <InputComponent
                        inputType={EActivityType.Textfield}
                        wrapperLabel={t(k.NOTES)}
                        boldLabel
                        hideIcon
                        value={progressValue?.comment ?? ""}
                        multiline
                        disabled={progressValue.isArchived}
                        minRows={5}
                        testId="measurement_progress_comment_input"
                        placeholder={t(k.ENTER_HERE)}
                        onChange={handleOnChangeComment}
                    />
                    <InputComponent
                        testId="measurement_progress_date_input"
                        inputType={EActivityType.DateTime}
                        wrapperLabel={t(k.DATE_OF_ADDITION)}
                        boldLabel
                        disabled={progressValue.isArchived || !createNew}
                        value={progressValue?.date ?? new Date(Date.now())}
                        name="date-of-addition"
                        placeholder={t(k.DATE_OF_ADDITION)}
                        hideIcon
                        noMinWidth
                        shouldCloseOnSelect
                        onChange={handleOnChangeDate}
                    />
                </InfoCard>
            </ModalContent.Body>

            <ModalContent.Footer className="measurement-progress-form--footer">
                <Button onClick={handleCancel} variant="danger" transparent>
                    {t(k.CANCEL)}
                </Button>

                <ButtonGroup className="buttons">
                    {!createNew && (
                        <Button
                            onClick={handleArchive}
                            variant="bordered"
                            transparent
                        >
                            {progressValue.isArchived
                                ? t(k.UN_ARCHIVE)
                                : t(k.ARCHIVE)}
                        </Button>
                    )}
                    {progressValue.isArchived ? (
                        canDelete && (
                            <Button onClick={handleDelete} variant="danger">
                                {t(k.DELETE)}
                            </Button>
                        )
                    ) : (
                        <Button onClick={handleSave} variant="success">
                            {t(k.SAVE)}
                        </Button>
                    )}
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default MeasurementProgressForm;
