import React from "react";

import { useBarcode } from "next-barcode";

import IBarcodeProps from "../IBarcodeProps";

const BarcodeCode128: React.FC<IBarcodeProps> = (props) => {
    const { value, hideBarcodeLabel } = props;

    const { inputRef } = useBarcode({
        value,
        options: {
            format: "CODE128",
            background: "transparent",
            height: 30,
            fontSize: 12,
            margin: 0,
            displayValue: !hideBarcodeLabel,
        },
    });

    return (
        <svg
            className={props.onClick ? "cursor-pointer" : undefined}
            ref={inputRef}
        />
    );
};

export default BarcodeCode128;
