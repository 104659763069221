import { useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { IAppState } from "store/IAppState";
import { ELayoutEnvironment } from "store/uiSettings/reducer";

interface IProps {
    value?: string;

    imgId?: string;
    blobSrc?: IBlobSrcType;

    timestamp?: number;
}

function buildImgSrc(
    isWithinTeams: boolean,
    tenantId?: string,
    imgId?: string,
    timestamp?: number,
) {
    if (!imgId) {
        return;
    }

    const hostUrl = `/api/picture/${imgId}`;
    const queryParams: string[] = [];

    if (isWithinTeams && tenantId) {
        queryParams.push(`tenantId=${tenantId}`);
    }

    if (timestamp) {
        queryParams.push(`t=${timestamp}`);
    }

    if (queryParams.length > 0) {
        return `${hostUrl}?${queryParams.join("&")}`;
    }

    return hostUrl;
}

const ImageComponent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { blobSrc, timestamp } = props;

    const imgId = props.imgId ?? blobSrc?.uploadedFileId;

    const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);

    const imgRef = useRef<HTMLImageElement>(null);

    const layoutEnvironment = useSelector(
        (appState: IAppState) => appState.uiSettingsViewState.layoutEnvironment,
    );

    const tenantId = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.tenantId,
    );

    const blobUrl = useMemo(() => {
        if (blobSrc?.fileName) {
            return `/api/blob/image/?fileName=${encodeURIComponent(blobSrc.fileName)}`;
        }
    }, [blobSrc]);

    const srcById = useMemo(() => {
        const isWithinTeams = layoutEnvironment === ELayoutEnvironment.TEAMS;

        return buildImgSrc(isWithinTeams, tenantId, imgId, timestamp);
    }, [layoutEnvironment, tenantId, imgId, timestamp]);

    const value =
        props.value && props.value.length > 0
            ? props.value
            : (blobSrc?.value ?? "");

    const src = value && value.length > 0 ? value : (blobUrl ?? srcById);

    useEffect(() => {
        if (src) {
            if (isLoading === undefined && imgRef.current?.complete === false) {
                setIsLoading(true);
            }
        } else {
            setIsLoading(undefined);
        }
    }, [src, imgRef]);

    const handleOnLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            <AnimatedSpinner
                isVisible={isLoading}
                position="center"
                aligned="center"
            />

            <img
                ref={imgRef}
                src={src}
                onLoad={handleOnLoad}
                onError={handleOnLoad}
            />
        </>
    );
};

export default ImageComponent;
