import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";

const CustomListItemDateTimeSelector: React.FC<
    ISharedPropertyInputProps<Date>
> = (props) => {
    const { values, errors, disabled } = props;

    const { parentId, isRequired, label } = props.property;

    const { t } = useTranslation();

    const handleChange = (e: IInputChangeEvent<Date | null>) => {
        props.onChange(parentId, e.value ? [e.value] : []);
    };

    const invalid = Boolean(errors[parentId]);

    return (
        <InputComponent
            inputType={EActivityType.DateTime}
            id={`form.${parentId}`}
            value={values && values.length > 0 && values[0] ? values[0] : null}
            disabled={disabled}
            placeholder={t(k.ENTER_HERE)}
            size={"large"}
            wrapperLabel={label}
            required={isRequired}
            hideIcon
            invalid={invalid}
            errors={errors[parentId]}
            onChange={handleChange}
        />
    );
};

export default CustomListItemDateTimeSelector;
