import { Action } from "redux";

import IBarcodePreviewRequest from "./IBarcodePreviewRequest";
import { BarcodeType } from "models/enums/BarcodeType";

export const SHOW_BARCODE_PREVIEW = "SHOW_BARCODE_PREVIEW";
export const HIDE_BARCODE_PREVIEW = "HIDE_BARCODE_PREVIEW";

export const SHOW_BARCODE_SCANNER = "SHOW_BARCODE_SCANNER";
export const HIDE_BARCODE_SCANNER = "HIDE_BARCODE_SCANNER";

interface IShowPreviewAction extends Action<typeof SHOW_BARCODE_PREVIEW> {
    payload: IBarcodePreviewRequest;
}

interface IHidePreviewAction extends Action<typeof HIDE_BARCODE_PREVIEW> {}

interface IShowScannerAction extends Action<typeof SHOW_BARCODE_SCANNER> {}

interface IHideScannerAction extends Action<typeof HIDE_BARCODE_SCANNER> {
    payload?: { barcode?: string; type?: BarcodeType };
}

export type BarcodesActionTypes =
    | IShowPreviewAction
    | IHidePreviewAction
    | IShowScannerAction
    | IHideScannerAction;
