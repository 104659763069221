import "./BarcodeField.scss";

import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { isValid } from "date-fns";

import k from "i18n/keys";

import { IInputProps, IWithDebounce } from "../IInputProps";
import Textfield from "../textfield/Textfield";
import Barcode from "common/components/barcode/Barcode";
import Button from "common/components/buttons/Button";
import BarcodeIcon from "common/components/icons/icon-barcode/BarcodeIcon";
import { ValidateBarcode } from "common/components/validation/BarcodeValidation";
import { useGenerateBarcodeCode } from "components/common/shared-properties/api/hooks";
import { BarcodeType } from "models/enums/BarcodeType";
import IBarcodePreviewRequest from "store/barcode/IBarcodePreviewRequest";
import { showBarcodePreview, showScanner } from "store/barcode/actions";

export interface IBarcodeProps extends IWithDebounce {
    barcodeType?: BarcodeType;
    isGenerate?: boolean;
}

type Props = IInputProps<string> & IBarcodeProps;

const WAIT_INTERVAL = 2000;

const BarcodeField = (props: Props) => {
    const {
        id,
        parentId,
        hideIcon,
        completed,
        invalid,
        disabled,
        preview,
        error,
        wrapperClassName,
        isGenerate,
        name,
        withDebounce,
        barcodeType = BarcodeType.EAN13,
    } = props;

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if (String(props.value) !== value) {
            setValue(String(props.value));
        }
    }, [props.value]);

    const [isChanged, setIsChanged] = useState(false);

    const timer = useRef<any>(null);

    useEffect(() => {
        if (!withDebounce) {
            triggerChange(false);

            return;
        }

        timer.current = setTimeout(() => {
            triggerChange(true);
        }, WAIT_INTERVAL);

        return () => clearTimeout(timer.current);
    }, [value, withDebounce]);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const triggerChange = (withDelay: boolean) => {
        if (isChanged) {
            setIsChanged(false);

            const isInvalid = ValidateBarcode(value, barcodeType) === false;

            props.onChange?.({
                id,
                name,
                value,
                withDelay,
                parentId,
                invalid: isInvalid,
            });
        }
    };

    const handleOnChange = (barcode: string) => {
        setIsChanged(true);

        setValue(barcode);
    };

    const handleOnBlur = () => {
        props.onBlur?.(id, name);

        clearTimeout(timer.current);

        triggerChange(false);
    };

    const handleOnEnter = async () => {
        clearTimeout(timer.current);

        triggerChange(false);
    };

    const { refetch: refetchGenerateBarcode } = useGenerateBarcodeCode(false);

    const handleGenerate = async () => {
        setIsLoading(true);

        const { data: barcode } = await refetchGenerateBarcode();

        if (barcode) {
            triggerOnGenerateOrScan(barcode, BarcodeType.EAN13);
        }

        setIsLoading(false);
    };

    const handleScan = async () => {
        setIsLoading(true);

        const { barcode, type } = (await showScanner()(dispatch)) ?? {};

        if (barcode !== undefined && type !== undefined) {
            triggerOnGenerateOrScan(barcode, type);
        }

        setIsLoading(false);
    };

    const triggerOnGenerateOrScan = (barcode: string, type: BarcodeType) => {
        clearTimeout(timer.current);

        setIsChanged(false);

        setValue(barcode);

        const isValid =
            (isGenerate || type === barcodeType) &&
            ValidateBarcode(barcode, type);

        props.onChange?.({
            id,
            name,
            value: barcode,
            parentId,
            invalid: !isValid,
        });
    };

    const handleBarcodePreview = () => {
        const request: IBarcodePreviewRequest = {
            value,
            type: barcodeType,
        };

        dispatch<any>(showBarcodePreview(request));
    };

    const className = useMemo(() => {
        const result = ["barcode-field"];
        if (invalid) {
            result.push("barcode-field--invalid");
        }
        if (wrapperClassName) {
            result.push(wrapperClassName);
        }
        return result.join(" ");
    }, [invalid, wrapperClassName]);

    return (
        <React.Fragment>
            {completed ? (
                <div
                    className={`barcode-field--preview${
                        wrapperClassName ? " " + wrapperClassName : ""
                    }`}
                >
                    <Barcode
                        value={value}
                        barcodeType={barcodeType}
                        onClick={handleBarcodePreview}
                    />

                    {preview || disabled
                        ? undefined
                        : props.clearControl && (
                              <div className="barcode-field--clear">
                                  {props.clearControl}
                              </div>
                          )}
                </div>
            ) : (
                <label className={className} htmlFor={id}>
                    {hideIcon ? undefined : (
                        <div className="icon-field">
                            <BarcodeIcon invalid={invalid} />
                        </div>
                    )}

                    <div className="barcode-field__scan-button no-print">
                        {isGenerate ? (
                            <Button
                                size="small"
                                transparent
                                onClick={handleGenerate}
                                disabled={disabled || isLoading}
                                isBusy={isLoading}
                                variant={invalid ? "danger" : undefined}
                            >
                                {t(k.GENERATE_BARCODE)}
                            </Button>
                        ) : (
                            <Button
                                size="small"
                                transparent
                                onClick={handleScan}
                                disabled={disabled || isLoading}
                                isBusy={isLoading}
                                variant={invalid ? "danger" : undefined}
                            >
                                {t(k.SCAN_BARCODE)}
                            </Button>
                        )}

                        {isGenerate && (
                            <Textfield.Error>{error}</Textfield.Error>
                        )}
                    </div>

                    {isGenerate ? null : (
                        <Textfield
                            id={id}
                            value={value}
                            disabled={disabled || isLoading}
                            invalid={invalid}
                            isBusy={isLoading}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            onEnter={handleOnEnter}
                            placeholder={t(k.OR_ENTER_BARCODE_HERE)}
                            helperText={
                                <Textfield.Error>
                                    {typeof error === "string"
                                        ? error
                                        : error?.map((x) => (
                                              <div key={x}>{x}</div>
                                          ))}
                                </Textfield.Error>
                            }
                        />
                    )}
                </label>
            )}
        </React.Fragment>
    );
};

export default BarcodeField;
