import "./ImageCard.scss";

import React from "react";

import ImageComponent from "../ImageComponent";
import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";

interface IProps {
    value?: string;

    imgId?: string;

    blobSrc?: IBlobSrcType;

    completedAt?: Date;

    className?: string;

    onClick?: () => void;
}

const ImageCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value, imgId, blobSrc, completedAt, className } = props;

    return (
        <div
            className={
                className
                    ? `image-card--wrapper ${className}`
                    : "image-card--wrapper"
            }
            onClick={props.onClick}
        >
            <ImageComponent
                timestamp={completedAt?.valueOf()}
                value={value}
                imgId={imgId}
                blobSrc={blobSrc}
            />

            {props.children && (
                <div className="image-card--menu">{props.children}</div>
            )}
        </div>
    );
};

export default ImageCard;
