import i18n, { TFunction } from "i18next";

import k from "i18n/keys";

import { MeasurementAccessRoleEnum } from "../api/EMeasurementAccessRole";
import { EMeasurementGrading } from "../api/EMeasurementGrading";
import IMeasurementActionSetDTO from "../api/IMeasurementActionSetDTO";
import { IMeasurementDTO } from "../api/IMeasurementDTO";
import { DataPerYear } from "../components/MeasurementChart";
import { IMeasurementProgressValueDTO } from "../measurement-progress-value/api/IMeasurementProgressValueDTO";
import { IGroupedValueLabelItem } from "common/IValueLabelItem";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputActionDTO } from "components/common/actions/api/IInputActionDTO";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink from "http/ITableSetWithParentLink";
import { MonthsKeys } from "i18n/keySets/MonthKeys";
import { QuartersKeys } from "i18n/keySets/QuarterKeys";
import { Months } from "models/enums/Months";
import { Quarters } from "models/enums/Quarters";

export enum EDisplayProgressType {
    Monthly,
    Quarterly,
}

export const progressTypes = {
    [EDisplayProgressType.Monthly]: k.MONTHLY,
    [EDisplayProgressType.Quarterly]: k.QUARTERLY,
};

export const progressTypeOptions = (i18next: typeof i18n) => [
    {
        label: i18next.t(progressTypes[EDisplayProgressType.Monthly]),
        value: EDisplayProgressType.Monthly,
    },
    {
        label: i18next.t(progressTypes[EDisplayProgressType.Quarterly]),
        value: EDisplayProgressType.Quarterly,
    },
];

export const validateMeasurement = (measurement: IMeasurementDTO) => {
    const errors: IValidationMessages = {};

    if (!measurement.name) {
        errors[`measurement.${measurement.id}.name`] = k.REQUIRED;
    }

    if (
        !!measurement.startDate &&
        !!measurement.endDate &&
        measurement.startDate > measurement.endDate
    ) {
        errors[`measurement.${measurement.id}.startDate`] =
            k.START_DATE_GREATER_THAN_END_DATE;
    }

    return errors;
};

export const measurementStatusLocales = {
    [EMeasurementGrading.OnTrack]: k.ON_TRACK,
    [EMeasurementGrading.Behind]: k.BEHIND,
    [EMeasurementGrading.AtRisk]: k.AT_RISK,
    [EMeasurementGrading.Blocked]: k.BLOCKED,
    [EMeasurementGrading.Completed]: k.COMPLETED,
    [EMeasurementGrading.Draft]: k.DRAFT,
};

export const measurementStatusOptions = (i18next: typeof i18n) =>
    [
        {
            value: EMeasurementGrading.OnTrack,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.OnTrack],
            ),
        },
        {
            value: EMeasurementGrading.Behind,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Behind],
            ),
        },
        {
            value: EMeasurementGrading.AtRisk,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.AtRisk],
            ),
        },
        {
            value: EMeasurementGrading.Blocked,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Blocked],
            ),
        },
        {
            value: EMeasurementGrading.Completed,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Completed],
            ),
        },
        {
            value: EMeasurementGrading.Draft,
            label: i18next.t(
                measurementStatusLocales[EMeasurementGrading.Draft],
            ),
        },
    ] as IGroupedValueLabelItem<EMeasurementGrading, string>[];

export const decomposeMeasurementAccessRole = (
    accessRole?: MeasurementAccessRoleEnum,
) => {
    let canView = undefined;
    let canEditInPreview = undefined;
    let canEdit = undefined;
    let canCreate = undefined;
    let canEverything = undefined;

    switch (accessRole) {
        case MeasurementAccessRoleEnum.CanEverything:
            canEverything = true;
        case MeasurementAccessRoleEnum.CanCreate:
            canCreate = true;
        case MeasurementAccessRoleEnum.CanEdit:
            canEdit = true;
        case MeasurementAccessRoleEnum.CanEditInPreview:
            canEditInPreview = true;
        case MeasurementAccessRoleEnum.CanView:
            canView = true;
            break;
    }

    return { canView, canEditInPreview, canEdit, canCreate, canEverything };
};

export const getMeasurementProgress = (measurement?: IMeasurementDTO) => {
    if (!measurement) {
        return 0;
    }

    const progress =
        measurement.endValue !== 0
            ? ((measurement?.currentValue ?? measurement?.startValue ?? 0) /
                  measurement.endValue) *
              100
            : 0;
    return parseFloat(progress.toFixed(2));
};

export const getProgressByYearAndMonth = (
    data: IMeasurementProgressValueDTO[],
    startDate?: Date,
    endDate?: Date,
): Record<number, DataPerYear> => {
    const dataPerYearAndMonth: Record<number, DataPerYear> = {};

    if (!data || data.length === 0) return dataPerYearAndMonth;

    const years = data.map((item) => new Date(item.date!).getFullYear());
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);

    const minDate = startDate ? new Date(startDate) : new Date(minYear, 0);
    const maxDate = endDate ? new Date(endDate) : new Date(maxYear, 11, 31);

    for (
        let year = minDate.getFullYear();
        year <= maxDate.getFullYear();
        year++
    ) {
        let startMonth = 0;
        let endMonth = 11;

        if (year === minDate.getFullYear()) {
            startMonth = minDate.getMonth();
        }
        if (year === maxDate.getFullYear()) {
            endMonth = maxDate.getMonth();
        }

        const months: string[] = [];
        for (let m = startMonth; m <= endMonth; m++) {
            const monthKey = MonthsKeys[m as Months];
            months.push(monthKey);
        }

        const y = new Array(months.length).fill(0);

        dataPerYearAndMonth[year] = { x: months, y };
    }

    data.forEach((item) => {
        if (item.date) {
            const itemDate = new Date(item.date);

            if (itemDate >= minDate && itemDate <= maxDate) {
                const month = itemDate.getMonth();
                const year = itemDate.getFullYear();

                if (dataPerYearAndMonth[year]) {
                    const months = dataPerYearAndMonth[year].x;
                    const monthKey = MonthsKeys[month as Months];
                    const monthIndex = months.indexOf(monthKey);

                    if (monthIndex !== -1) {
                        dataPerYearAndMonth[year].y[monthIndex] += item.value;
                    }
                }
            }
        }
    });

    return dataPerYearAndMonth;
};

export const getProgressByYearAndQuarter = (
    data: IMeasurementProgressValueDTO[],
    startDate?: Date,
    endDate?: Date,
): Record<number, DataPerYear> => {
    const dataPerYearAndQuarter: Record<number, DataPerYear> = {};

    if (!data || data.length === 0) return dataPerYearAndQuarter;

    const years = data.map((item) => new Date(item.date!).getFullYear());
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);

    const minDate = startDate ? new Date(startDate) : new Date(minYear, 0);
    const maxDate = endDate ? new Date(endDate) : new Date(maxYear, 11, 31);

    for (
        let year = minDate.getFullYear();
        year <= maxDate.getFullYear();
        year++
    ) {
        let startQuarter = 0;
        let endQuarter = 3;

        if (year === minDate.getFullYear()) {
            startQuarter = Math.floor(minDate.getMonth() / 3);
        }
        if (year === maxDate.getFullYear()) {
            endQuarter = Math.floor(maxDate.getMonth() / 3);
        }

        const quarters: string[] = [];
        for (let q = startQuarter; q <= endQuarter; q++) {
            const quarterKey = QuartersKeys[q as Quarters];
            quarters.push(quarterKey);
        }

        const y = new Array(quarters.length).fill(0);

        dataPerYearAndQuarter[year] = { x: quarters, y };
    }

    data.forEach((item) => {
        if (item.date) {
            const itemDate = new Date(item.date);

            if (itemDate >= minDate && itemDate <= maxDate) {
                const quarter = Math.floor(itemDate.getMonth() / 3);
                const year = itemDate.getFullYear();

                if (dataPerYearAndQuarter[year]) {
                    const quarters = dataPerYearAndQuarter[year].x;
                    const quarterKey = QuartersKeys[quarter as Quarters];
                    const quarterIndex = quarters.indexOf(quarterKey);

                    if (quarterIndex !== -1) {
                        dataPerYearAndQuarter[year].y[quarterIndex] +=
                            item.value;
                    }
                }
            }
        }
    });

    return dataPerYearAndQuarter;
};

export const getProgressLineData = (
    dataPerYearAndMonth: Record<number, DataPerYear>,
    t: TFunction<"translation", undefined>,
) => {
    const data = { x: [] as string[], y: [] as number[] };

    let sum = 0;

    Object.keys(dataPerYearAndMonth).forEach((year) => {
        data.x.push(
            ...dataPerYearAndMonth[parseInt(year)].x.map(
                (month) => `${t(month)} ${year}`,
            ),
        );
        data.y.push(
            ...dataPerYearAndMonth[parseInt(year)].y.map((y) => {
                sum += y;
                return sum;
            }),
        );
    });

    return data;
};

export const convertToTableSet = (
    inputActionSets: IMeasurementActionSetDTO[],
) => {
    return inputActionSets.reduce<ITableSet<IMeasurementActionSetDTO>>(
        (acc, inputActionSet) => {
            acc.ids.push(inputActionSet.id);
            acc.values[inputActionSet.id] = inputActionSet;

            return acc;
        },
        { ids: [], values: {} },
    );
};

export const convertToTableSetWithParentLink = (
    inputActionsByInputActionSetId: IDictionary<IInputActionDTO[]>,
) => {
    return Object.keys(inputActionsByInputActionSetId).reduce<
        ITableSetWithParentLink<
            IInputActionDTO,
            "inputActionIdsByInputActionSetId"
        >
    >(
        (acc, inputActionSetId) => {
            inputActionsByInputActionSetId[inputActionSetId].forEach(
                (inputAction) => {
                    acc.values[inputAction.id] = inputAction;
                    if (
                        !acc.parents?.inputActionIdsByInputActionSetId?.[
                            inputActionSetId
                        ]
                    ) {
                        acc.parents!.inputActionIdsByInputActionSetId![
                            inputActionSetId
                        ] = [];
                    }
                    acc.parents?.inputActionIdsByInputActionSetId?.[
                        inputActionSetId
                    ]?.push(inputAction.id);

                    acc.ids.push(inputAction.id);
                },
            );

            return acc;
        },
        {
            ids: [],
            values: {},
            parents: { inputActionIdsByInputActionSetId: {} },
        },
    );
};
