import "./ModalBarcodeScannerManager.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import k from "i18n/keys";

import Modal from "common/components/modals/Modal";
import { lazyMinLoadTime } from "common/utils/lazyMinLoadTime";
import { BarcodeType } from "models/enums/BarcodeType";
import LazyLoadingFallback from "routes/components/LazyLoadingFallback";
import { IAppState } from "store/IAppState";
import { hideScanner } from "store/barcode/actions";

const BarcodeScannerModalBody = lazyMinLoadTime(
    () => import("./components/BarcodeScannerModalBody"),
);

interface IStateProps {
    show?: boolean;
}

interface IDispatchProps {
    onHide: (barcode?: string, type?: BarcodeType) => void;
}

type Props = IStateProps & IDispatchProps;

const ModalBarcodeScannerManager = (props: Props) => {
    const { show } = props;

    const { t } = useTranslation();

    return show ? (
        <Modal show onHide={props.onHide}>
            <Modal.Title>{t(k.SCAN_BARCODE)}</Modal.Title>

            <Modal.Body>
                <React.Suspense fallback={<LazyLoadingFallback />}>
                    <BarcodeScannerModalBody hide={props.onHide} />
                </React.Suspense>
            </Modal.Body>
        </Modal>
    ) : null;
};

const mapStateToProps = (state: IAppState): IStateProps => {
    const { isScannerVisible: isVisible } = state.barcodeViewState;

    return {
        show: isVisible,
    };
};

const actions: IDispatchProps = {
    onHide: hideScanner,
};

export default connect(mapStateToProps, actions)(ModalBarcodeScannerManager);
