import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { LocaleId } from "AppLocale";

import k from "i18n/keys";

import { IMenuOption } from "common/components/buttons/menu-button/IMenuOption";
import MenuButton from "common/components/buttons/menu-button/MenuButton";
import { KebabIcon } from "common/components/icons";
import { updateLocale } from "store/auth/actions";

enum MenuOptions {
    MyProfile = "my-profile",
    HelpAndSupport = "help-and-support",
    ChangeLanguage = "change-language",
    SignOut = "sign-out",
}

interface IKebabMenuProps {
    onMyProfileClick?: () => void;
    onHelpAndSupportClick?: () => void;
    onLanguageChange?: () => void;
    onSignOut?: () => void;
}

interface IDispatchProps {
    updateLocale: (localeId: LocaleId) => void;
}

type Props = IKebabMenuProps & IDispatchProps;

const KebabMenu = (props: Props) => {
    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const handleOnMenuSelect = (selectedId: string) => {
        switch (selectedId as MenuOptions) {
            case MenuOptions.MyProfile:
                props.onMyProfileClick?.();
                break;
            case MenuOptions.HelpAndSupport:
                props.onHelpAndSupportClick?.();
                break;
            case MenuOptions.ChangeLanguage:
                props.onLanguageChange?.();
                break;
            case MenuOptions.SignOut:
                props.onSignOut?.();
                break;
        }
    };

    const options: IMenuOption[] = [
        {
            id: MenuOptions.MyProfile,
            label: t(k.MY_PROFILE),
        },
        {
            id: MenuOptions.HelpAndSupport,
            label: t(k.HELP_AND_SUPPORT),
        },
        {
            id: MenuOptions.ChangeLanguage,
            label:
                localeId === LocaleId.Sv
                    ? t(k.CHANGE_LANGUAGE_TO_ENGLISH)
                    : t(k.CHANGE_LANGUAGE_TO_SWEDISH),
        },
        {
            id: MenuOptions.SignOut,
            label: t(k.SIGN_OUT),
        },
    ];

    return (
        <MenuButton
            icon={<KebabIcon variant="white" />}
            onSelect={handleOnMenuSelect}
            options={options}
            background="dark"
            testId="menu-section__menu-btn"
        />
    );
};

const actions = {
    updateLocale,
};

export default connect(null, actions)(KebabMenu);
