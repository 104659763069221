import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { IShowConfirmArgs, showConfirmDispatch } from "store/confirms/actions";

type Props = ISharedPropertyInputProps<string> & { isGenerate?: boolean };

const CustomListItemBarcodeSelector: React.FC<Props> = (props) => {
    const { values, errors, disabled, isGenerate, setErrors } = props;

    const { parentId, isRequired, label, barcodeType } = props.property;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isCompleted, setIsCompleted] = useState<boolean | undefined>(
        values && values.length > 0 ? Boolean(values[0]) : undefined,
    );

    const handleChange = (e: IInputChangeEvent<string>) => {
        props.onChange(parentId, [e.value]);

        if (e.value.length === 0) {
            updateErrors(undefined);
        }

        if (e.invalid) {
            updateErrors(t(k.INVALID));
        } else {
            updateErrors(undefined);
            setIsCompleted(true);
        }
    };

    const updateErrors = (newError?: string) => {
        if (setErrors) {
            setErrors((prev) => ({
                ...prev,
                [parentId]: newError,
            }));
        }
    };

    const handleUndo = async () => {
        const args: IShowConfirmArgs = {
            message: t(k.ARE_YOU_SURE),
        };

        if (await showConfirmDispatch(dispatch, args)) {
            props.onChange(parentId, []);

            setIsCompleted(false);
        }
    };

    return (
        <InputComponent
            withDebounce
            inputType={
                isGenerate
                    ? EActivityType.GenerateBarcode
                    : EActivityType.Barcode
            }
            id={`form.${parentId}`}
            value={values && values.length > 0 ? values[0] : ""}
            invalid={Boolean(errors[parentId])}
            hideIcon
            completed={isCompleted}
            disabled={disabled}
            barcodeType={barcodeType}
            clearControl={
                <IconButton circle={true} onClick={handleUndo}>
                    <TrashIcon dotIcon={true} />
                </IconButton>
            }
            errors={errors[parentId]}
            wrapperLabel={
                <>
                    {label}

                    {isRequired && (
                        <React.Fragment>
                            &nbsp;
                            <span className="text--danger">*</span>
                        </React.Fragment>
                    )}
                </>
            }
            onChange={handleChange}
        />
    );
};

export default CustomListItemBarcodeSelector;
