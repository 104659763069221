import "./InputActionTaskList.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import {
    SortEnd,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

import k from "i18n/keys";

import ActionTags from "../ActionTags";
import {
    IInputActionChangeEvent,
    IInputActionProps,
} from "../IInputActionProps";
import ActionTextField from "./components/ActionTextField";
import Button from "common/components/buttons/Button";
import { LoadingIcon } from "common/components/icons";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import { arrayMoveTo } from "common/utils/arrayUtils";
import IDictionary from "common/viewModels/IDictionary";
import { IInputActionDTO } from "components/common/actions/api/IInputActionDTO";

const DragHandle = SortableHandle(() => (
    <div className="sortable-handle">
        <FaBars />
    </div>
));

interface ISortableItemProps extends Pick<IInputActionProps, "errors"> {
    value: IInputActionDTO;
    order: number;
    inputRefs?: IDictionary<React.RefObject<HTMLInputElement>>;
    editMode?: boolean;
    editable?: boolean;
    isDisabled?: boolean; // do not rename to disabled, it will break sortable

    onDeleteInputAction: (id: string) => void;
    onDeleteListItem?: (id: string) => void;
    onAssignUser?: (
        id: string,
        inputActionId: string,
        remove?: boolean,
    ) => void;
    onChange?: <V>(e: IInputActionChangeEvent<V>) => void;
    onSaveInputAction?: () => void;
    onNewImprovement?: (inputActionId: string) => void;
    onFocus?: (id: string, name?: string) => void;
    onBlur?: (id: string, name?: string) => void;
}

const SortableItem = SortableElement<ISortableItemProps>(
    (props: ISortableItemProps) => {
        const {
            value,
            order,
            errors,
            editMode,
            isDisabled: disabled,
            inputRefs,
            editable,
        } = props;

        const { t } = useTranslation();
        const hasErrors = Boolean(
            errors ? errors[`ActionInput.${value.id}.Label`] : false,
        );

        const handleOnDelete = () => {
            if (!editMode) {
                props.onDeleteInputAction(value.id);
            } else {
                props.onDeleteListItem?.(value.id);
            }
        };

        const handleOnAssignUser = (userId: string) => {
            props.onAssignUser?.(
                userId,
                value.id,
                value.assignedUsersIds?.includes(userId),
            );
        };

        const handleOnNewImprovement = () => {
            props.onNewImprovement?.(value.id);
        };

        const handleOnFocus = () => {
            props.onFocus?.(value.id);
        };

        const handleOnBlur = () => {
            props.onBlur?.(value.id);
            props.onSaveInputAction?.();
        };

        return (
            <div
                className="input-action-tasklist--item"
                data-testid={`tasklist-item-${order}`}
            >
                <div className="input-action-tasklist--container">
                    <div className="tasklist-edit">
                        {!disabled && editable && <DragHandle />}
                        <Checkbox
                            bold
                            name="taskValue"
                            id={value.id}
                            value={value.taskValue ?? false}
                            disabled={disabled}
                            preview={!editable}
                            onChange={props.onChange}
                        />
                        <ActionTextField
                            testId="tasklist-input"
                            name="label"
                            fullWidth={false}
                            wrapperClassName="tasklist-input"
                            noMinWidth
                            editMode={editMode}
                            disabled={disabled}
                            editable={editable}
                            inputRef={inputRefs?.[value.id]}
                            id={value.id}
                            value={value.label}
                            placeholder={t(k.LABEL)}
                            onChange={props.onChange}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            invalid={hasErrors}
                            error={
                                errors?.[`ActivityListItem.${value.id}.Label`]
                            }
                        />
                        <ActionTags
                            editMode={editMode}
                            disabled={disabled || !editable}
                            completedAt={value.completedAt}
                            completedBy={value.completedBy}
                            isCompleted={value.taskValue}
                            onNewImprovement={handleOnNewImprovement}
                            assignedUserIds={value.assignedUsersIds}
                            onAssignUser={handleOnAssignUser}
                            onDelete={handleOnDelete}
                        />
                    </div>
                </div>
            </div>
        );
    },
);

const SortableList = SortableContainer<{ children: React.ReactNode }>(
    (props: { children: React.ReactNode }) => {
        return <div className="input-action-tasklist">{props.children}</div>;
    },
);

const InputActionTaskList = (props: IInputActionProps) => {
    const {
        inputActions,
        editMode,
        id,
        disabled,
        inputRefs,
        isLoading,
        editable = true,
    } = props;

    const { t } = useTranslation();

    const handleAddListItem = () => {
        props.onChangeList?.();
    };

    const handleDelete = (id: string) => {
        props.onChangeList?.(id);
    };

    const handleDeleteInputAction = (id: string) => {
        props.onDeleteInputAction?.(id);
    };

    const onSortEndHandler = (params: SortEnd) => {
        const sortedItems = arrayMoveTo(
            inputActions.map((item) => item.id),
            params.oldIndex,
            params.newIndex,
        );
        props.onSortList?.(id, sortedItems);
    };

    const shouldCancelStart = () => {
        return disabled || !editable;
    };

    return (
        <>
            {inputActions?.length > 0 ? (
                <SortableList
                    lockAxis="y"
                    useDragHandle={!disabled && editable}
                    useWindowAsScrollContainer
                    helperClass="input-action-tasklist--item--dragging"
                    onSortEnd={onSortEndHandler}
                    shouldCancelStart={shouldCancelStart}
                >
                    {inputActions?.map((value, index) => (
                        <SortableItem
                            editMode={editMode}
                            key={value.id}
                            index={index}
                            inputRefs={inputRefs}
                            order={index}
                            value={value}
                            errors={props.errors}
                            isDisabled={disabled}
                            editable={editable}
                            onChange={props.onChange}
                            onSaveInputAction={props.onSaveInputAction}
                            onDeleteInputAction={handleDeleteInputAction}
                            onDeleteListItem={handleDelete}
                            onAssignUser={props.onAssignUser}
                            onNewImprovement={props.onNewImprovement}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                        />
                    ))}
                </SortableList>
            ) : (
                <p className="input-action-tasklist--empty">
                    {t(k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN)}
                </p>
            )}
            {!disabled && editable && (
                <div className="step-activity-options--button">
                    <Button
                        transparent
                        size="small"
                        icon={
                            isLoading ? <LoadingIcon /> : <FiPlus size="22" />
                        }
                        disabled={isLoading}
                        onMouseDown={handleAddListItem}
                    >
                        {t(k.ADD_TASK)}
                    </Button>
                </div>
            )}
        </>
    );
};

export default InputActionTaskList;
