import { FC, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ChecklistDetailsContext from "../../../../../../checklistContext";
import Button from "common/components/buttons/Button";
import { useUpdateChecklistMutation } from "components/checklists/api/hooks";
import IWorkflowRunRequest, {
    IChangedStepInstance,
} from "http/requests/IWorkflowRunRequest";

interface Props {}

const SaveButton: FC<Props> = (props) => {
    const {
        editedChecklistValues,
        setEditedChecklistValues,
        onHaveChanges,
        errors,
        setUsersAffected,
        setEditedSteps,
        checkCanSeeAll: checkIfAdmin,
        selectedChecklistId,
        editedSteps,
        setEditMode,
        checklist,
        setNumberOfRemovedSteps,
    } = useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const { mutateAsync: saveChecklistValueMutatation } =
        useUpdateChecklistMutation();

    const hasErrors = Object.values(errors || {}).some(
        (value) => value !== undefined && value !== null && value !== "",
    );

    const handleSaveEdit = async () => {
        if (!hasErrors) {
            if ((editedChecklistValues || editedSteps) && selectedChecklistId) {
                const {
                    title: name,
                    startDate,
                    endDate,
                } = { ...checklist, ...editedChecklistValues };

                const newValues: IWorkflowRunRequest = {
                    id: selectedChecklistId,
                    name,
                    startDate,
                    endDate,
                    checkIfAdmin,
                    changedStepInstances: editedSteps
                        ? Object.values(editedSteps)
                        : undefined,
                };

                const removedSteps = editedSteps
                    ? Object.values(editedSteps).reduce(
                          (
                              accumulator: number,
                              value: IChangedStepInstance,
                          ) => {
                              return value.removeEditor
                                  ? accumulator + 1
                                  : accumulator;
                          },
                          0,
                      )
                    : 0;

                setNumberOfRemovedSteps(removedSteps);
                saveChecklistValueMutatation({
                    request: newValues,
                });
            }
            setEditMode(false);
            setEditedChecklistValues(undefined);
            setEditedSteps(undefined);
            setUsersAffected(undefined);
            onHaveChanges(false);
        }
    };

    return (
        <Button
            variant="success"
            size="small"
            title={hasErrors ? t(k.INVALID_FIELDS) : undefined}
            disabled={hasErrors}
            onClick={handleSaveEdit}
        >
            {t(k.SAVE)}
        </Button>
    );
};

export default SaveButton;
