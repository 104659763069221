import "./ActivityInstanceSetCardById.scss";

import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { MdReplay } from "react-icons/md";
import { useLocation } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import ActivityInstanceCardById from "./ActivityInstanceCardById";
import ActivityInstanceSetCard from "./ActivityInstanceSetCard";
import Button from "common/components/buttons/Button";
import ImagePreview from "common/components/images/image-preview/ImagePreview";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import IDictionary from "common/viewModels/IDictionary";
import { useActivityInstanceAttachmentsByParentId } from "components/checklists/api/hooks";
import ChecklistDetailsContext from "components/checklists/components/ChecklistListedContent/checklistContext";
import SortableActivityInstanceSet from "components/common/activities/SortableActivityInstanceSet";
import IImprovementFilter, {
    EImprovementView,
} from "components/improvements/api/IImprovementFIlter";
import ImprovementsDetailsContext from "components/improvements/components/ImprovementListedContent/improvementContext";
import IChecklistActivityMarkCompleted from "http/requests/IChecklistActivityMarkCompleted";
import isEmpty from "lodash/isEmpty";
import IActivityInstance from "models/IActivityInstance";
import IStepAvailableUsersDTO from "models/dto/IStepAvailableUsersDTO";
import { ActivityType } from "models/enums/ActivityType";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";

export interface IProps {
    id: string;

    orderIndex: number;

    preview?: boolean;

    availableUsers?: IStepAvailableUsersDTO;

    highlightedActivityInstanceIds?: IDictionary<boolean>;
    isImprovement?: boolean;
    isMarkCompletedBusy: boolean;
    invalidByActivityInstanceId: Record<string, boolean | undefined>;

    onConfirmFinalize: () => void;
    onMarkCompleted: (request: IChecklistActivityMarkCompleted) => void;
}

const ActivityInstanceSetCardById = (props: IProps) => {
    const {
        id,
        orderIndex,
        preview,
        availableUsers,
        highlightedActivityInstanceIds,
        isImprovement,
        isMarkCompletedBusy,
        invalidByActivityInstanceId,
    } = props;

    const {
        checklist,
        stepInstances,
        activityInstanceSets,
        activityInstanceIdsBySet,
        activityInstances,
        profile,
        sectionRefs,
        improvement,
        setActivityInstances,
        setActivityInstanceIdsBySet,
        onShowModal,
    } = isImprovement
        ? useContext(ImprovementsDetailsContext)
        : useContext(ChecklistDetailsContext);

    const { data: attachments, isLoading: isLoadingAttachments } =
        useActivityInstanceAttachmentsByParentId(
            isImprovement ?? false,
            isImprovement ? improvement?.id : checklist?.id,
        );

    const { t } = useTranslation();

    const location = useLocation();

    const activityInstanceSet = activityInstanceSets?.values[id];
    const stepInstance = activityInstanceSet?.stepInstanceId
        ? stepInstances?.values[activityInstanceSet.stepInstanceId]
        : undefined;

    const handleShowImprovements = (onlyNotCompleted: boolean) => {
        const impFilter: IImprovementFilter = {
            [EImprovementView.activityInstanceSetId]: props.id,
            active: onlyNotCompleted,
            closed: !onlyNotCompleted,
        };

        const payload: IShowModalPayload = {
            modalFor: {
                type: "checklist_step",
                id: props.id ?? "",
            },

            type: ModalTypes.multiple_improvements,
            callerAddress: location.pathname,
            options: {
                impFilter,
                name: checklist?.title,
            },
        };

        onShowModal(payload);
    };

    const handleMarkCompleted = () => {
        const request: IChecklistActivityMarkCompleted = {
            id: id,
            completed: true,
        };

        props.onMarkCompleted(request);
    };

    const handleMarkCompletedUndo = () => {
        const request: IChecklistActivityMarkCompleted = {
            id: id,
            completed: false,
        };

        props.onMarkCompleted(request);
    };

    const handleNewImprovementClick = () => {
        const impFilter: IImprovementFilter = {
            [EImprovementView.activityInstanceSetId]: props.id,
            forName: activityInstanceSet?.title,
        };

        const payload: IShowModalPayload = {
            callerAddress: location.pathname,
            type: ModalTypes.select_improvement_form,
            modalFor: {
                type: "checklist_step",
                id: activityInstanceSet?.stepInstanceId ?? "",
            },
            options: { impFilter, createNew: true },
        };

        onShowModal(payload);
    };

    const handleAddActivityInstance = () => {
        setAddingNewInstances(true);

        const newCardId = uuidv4();

        const newActivityInstance: IActivityInstance = {
            activityInstanceSetId: id,
            id: newCardId,
            label: "",
            isRequired: false,
            isUserDefined: true,
            value: "",
            completed: false,
            assignedUserIds: [],
            assignedUsers: [],
            type: ActivityType.Tasklist,
            updatedBy: "",
            edit: true,
            isCreate: true,
        };

        setActivityInstances?.((prevState) => ({
            values: { ...prevState?.values, [newCardId]: newActivityInstance },
            ids: [...(prevState?.ids || []), newCardId],
        }));

        setActivityInstanceIdsBySet?.((prevState) => ({
            ...prevState,
            [id]: [...(prevState?.[id] || []), newCardId],
        }));
    };

    if (!activityInstanceSet) {
        return null;
    }

    const isStopped = checklist?.status === ChecklistDynamicStatus.Stopped;
    const isArchived = checklist?.status === ChecklistDynamicStatus.Archived;

    const disabledChecklist =
        preview ||
        isStopped ||
        isArchived ||
        stepInstance?.status === StepInstanceStatus.Blocked ||
        stepInstance?.status === StepInstanceStatus.Finalized;

    const setIsEmpty =
        activityInstanceIdsBySet &&
        isEmpty(activityInstanceIdsBySet[id]) &&
        activityInstanceSet.type === ActivityType.Tasklist;

    const disabledImprovement =
        preview ||
        improvement?.dynamicStatus === DynamicImprovementStatus.Closed;

    const disabled = isImprovement ? false : disabledChecklist;
    let noAddedTasksTranslationChecklist = "";

    if (!isImprovement) {
        if (!disabled) {
            noAddedTasksTranslationChecklist =
                k.NO_CHECKLIST_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY;
        } else {
            noAddedTasksTranslationChecklist =
                k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN;
        }
    }

    const noAddedTasksTranslationImprovement = isImprovement
        ? k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN
        : "";

    const emptyTranslation = isImprovement
        ? noAddedTasksTranslationImprovement
        : noAddedTasksTranslationChecklist;

    const addUserDefinedTasksEnabled =
        profile?.appFeatures?.userDefinedTasks &&
        activityInstanceSet.type === ActivityType.Tasklist &&
        (!isImprovement ? !disabled : true);

    const [addingNewInstances, setAddingNewInstances] = useState(false);

    const handleOnChangeActivityInstancesList = (
        activityInstanceSetId: string,
        activityInstanceIds: string[],
    ) => {
        setActivityInstanceIdsBySet?.((prevState) => ({
            ...prevState,
            [activityInstanceSetId]: activityInstanceIds,
        }));
    };

    const handleOnDraftEnd = (isSave?: boolean) => {
        if (isSave) {
            handleAddActivityInstance();
        } else {
            setAddingNewInstances(false);
        }
    };

    return (
        <ActivityInstanceSetCard
            id={id}
            type={activityInstanceSet.type}
            required={activityInstanceSet.isRequired}
            index={orderIndex}
            title={activityInstanceSet.title}
            description={activityInstanceSet.description}
            isLoadingAttachments={isLoadingAttachments}
            attachments={attachments && attachments[id]}
            notCompletedImprovementsCount={
                activityInstanceSet.notCompletedImprovementsCount
            }
            completedImprovementsCount={
                activityInstanceSet.completedImprovementsCount
            }
            onShowImprovements={handleShowImprovements}
            activityPhoto={
                activityInstanceSet.photoId && (
                    <ImagePreview imgId={activityInstanceSet.photoId} />
                )
            }
        >
            {activityInstanceSet.type === ActivityType.Tasklist ? (
                <SortableActivityInstanceSet
                    activityInstanceSet={activityInstanceSet}
                    activityInstanceIdsList={activityInstanceIdsBySet}
                    activityInstances={activityInstances}
                    availableUsers={availableUsers}
                    onChangeList={handleOnChangeActivityInstancesList}
                    sectionRefs={sectionRefs}
                    preview={preview}
                    onConfirmFinalize={props.onConfirmFinalize}
                    onNewImprovement={handleNewImprovementClick}
                    isImprovement={isImprovement}
                    invalidByActivityInstanceId={invalidByActivityInstanceId}
                    onDraftEnd={handleOnDraftEnd}
                />
            ) : (
                activityInstanceIdsBySet?.[id]?.map(
                    (activityInstanceId, index) => {
                        if (
                            activityInstanceSet.acceptMultiple &&
                            (activityInstanceSet.completed || disabled)
                        ) {
                            if (
                                index > 0 &&
                                activityInstanceIdsBySet[activityInstanceId] &&
                                index ===
                                    activityInstanceIdsBySet[activityInstanceId]
                                        .length -
                                        1
                            ) {
                                return null;
                            }
                        }

                        return (
                            <ActivityInstanceCardById
                                key={activityInstanceId}
                                id={activityInstanceId}
                                isFirst={index === 0}
                                isSetCompleted={activityInstanceSet.completed}
                                acceptMultiple={
                                    activityInstanceSet.acceptMultiple
                                }
                                multiSelectEnabled={
                                    activityInstanceSet.multiSelectEnabled
                                }
                                barcodeType={activityInstanceSet.barcodeType}
                                preview={preview}
                                isHighlighted={
                                    highlightedActivityInstanceIds
                                        ? highlightedActivityInstanceIds[
                                              activityInstanceId
                                          ]
                                        : undefined
                                }
                                availableUsers={availableUsers}
                                onConfirmFinalize={props.onConfirmFinalize}
                                onNewImprovement={handleNewImprovementClick}
                                isImprovement={isImprovement}
                                invalidByActivityInstanceId={
                                    invalidByActivityInstanceId
                                }
                            />
                        );
                    },
                )
            )}

            {setIsEmpty && (
                <p className="activity-instance--card--empty">
                    {t(emptyTranslation)}
                </p>
            )}

            {addUserDefinedTasksEnabled && (
                <div className="step-activity-options--button no-print">
                    <Button
                        transparent
                        size="small"
                        onClick={handleAddActivityInstance}
                        icon={<FiPlusCircle size="22" />}
                        disabled={addingNewInstances}
                        testId="addUserDefinedTask"
                    >
                        {t(k.ADD_TASK)}
                    </Button>
                </div>
            )}

            {disabled
                ? undefined
                : activityInstanceSet.acceptMultiple && (
                      <div className="checklist-activity-input--cmd no-print">
                          {activityInstanceSet.completed ? (
                              <React.Fragment>
                                  <Button
                                      onClick={handleMarkCompletedUndo}
                                      icon={<MdReplay className="cmd-reset" />}
                                      size="small"
                                      variant="gray"
                                      isBusy={isMarkCompletedBusy}
                                  >
                                      {t(k.RESET)}
                                  </Button>

                                  {!isImprovement && (
                                      <Button
                                          icon={
                                              <FaExclamationTriangle className="cmd-improvement" />
                                          }
                                          size="small"
                                          transparent
                                          onClick={handleNewImprovementClick}
                                      >
                                          {t(k.REGISTER_IMPROVEMENT)}
                                      </Button>
                                  )}
                              </React.Fragment>
                          ) : (
                              <Button
                                  onClick={handleMarkCompleted}
                                  size="small"
                                  variant="success"
                                  isBusy={isMarkCompletedBusy}
                              >
                                  {t(k.MARK_AS_COMPLETED)}
                              </Button>
                          )}
                      </div>
                  )}
        </ActivityInstanceSetCard>
    );
};

export default ActivityInstanceSetCardById;
