import "./MenuSection.scss";

import React, { useContext, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiFillThunderbolt } from "react-icons/ai";
import { FaRegFileAlt, FaTasks } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa6";
import { FiCheck, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { GrHostMaintenance } from "react-icons/gr";
import { HiDocumentDuplicate } from "react-icons/hi2";
import { MdSecurity, MdSpeed } from "react-icons/md";
import { SiGoogleanalytics } from "react-icons/si";
import { TiWarningOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { LocaleId, setLocale } from "AppLocale";

import k from "i18n/keys";

import Avatar from "../avatar/Avatar";
import Button from "../buttons/Button";
import IconButton from "../buttons/icon-button/IconButton";
import { ProcessChartIcon } from "../icons";
import CustomListIcon from "../icons/icon-custom-list/CustomListIcon";
import HRIcon from "../icons/icon-hr/HRIcon";
import MarketingSalesIcon from "../icons/icon-marketing-sales/MarketingSalesIcon";
import ResourcesIcon from "../icons/icon-resources/ResourcesIcon";
import RiskManagementIcon from "../icons/icon-risk-management/RiskManagementIcon";
import SeniorManagementIcon from "../icons/icon-senior-management/SeniorManagementIcon";
import SourcingPurchaseIcon from "../icons/icon-sourcing-purchase/SourcingPurchaseIcon";
import {
    IShowModalPayload,
    ModalTypes,
} from "../modal-manager/api/IModalManager";
import Confirm from "../modals/confirm/Confirm";
import Tooltip from "../tooltip/Tooltip";
import KebabMenu from "./components/KebabMenu";
import MenuNode from "./components/MenuNode";
import MobileMenuHeader from "./components/MobileMenuHeader";
import {
    CustomListItemsPaths,
    IMenuOption,
    PagesPaths,
    ProcessChartPaths,
} from "./utils/MenuPaths";
import useNavMenuOptions from "./utils/UseNavMenuOptions";
import { IMenuNavNodes } from "common/IMenuNavNodes";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { useCustomListOptionsForMenu } from "components/custom-list-page/api/hooks";
import { gbFlag, svFlag } from "i18n/components/flags";
import { IProfileDTO, IUserRoles } from "models/dto/IProfileDTO";
import { updateLocale } from "store/auth/actions";

const logoPath = "/svg/logo.svg";
const logoSmallPath = "/svg/logo-small.svg";
const DELAY_INTERVAL = 370;

export interface IMenuSectionProps {
    isMenuExpandedWhenPageLoads: boolean;

    profile?: IProfileDTO;

    roles: IUserRoles;

    onNavigateTo: (to: string) => void;
    onSignOut: () => void;
    updateMenuSettings: (isExpanded: boolean) => void;
}

interface IExpandedState {
    isFixed: boolean;
    isOpen: boolean;
}

interface IDispatchProps {
    updateLocale: (localeId: LocaleId) => void;
}

type Props = IMenuSectionProps & IDispatchProps;

const MenuSection: React.FC<Props> = (props) => {
    const { profile, roles } = props;

    const appFeatures = profile?.appFeatures ?? {};

    const { data: customListLinks } = useCustomListOptionsForMenu(
        appFeatures.customLists ?? false,
    );

    const modalManagerContext = useContext(ModalManagerContext);

    const {
        t,
        i18n: { language: localeId },
    } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    const [expandedState, setExpandedState] = useState<IExpandedState>({
        isFixed: true,
        isOpen: props.isMenuExpandedWhenPageLoads,
    });

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isHidingMobileMenu, setIsHidingMobileMenu] = useState(false);

    const [isLogoutConfirmVisible, setIsLogoutConfirmVisible] = useState(false);

    const handleToggleExpand = () => {
        const isNextExpanded = expandedState.isFixed === false;

        props.updateMenuSettings(isNextExpanded);

        setExpandedState((prev) => {
            return {
                isFixed: true,
                isOpen: !prev.isFixed,
            };
        });
    };

    const handleToggleMobileMenu = () => {
        setExpandedState({
            isFixed: true,
            isOpen: true,
        });

        if (isMobileMenuVisible) {
            return handleHideMobileMenu();
        } else {
            setIsMobileMenuVisible(true);
        }
    };

    const handleHideMobileMenu = () => {
        setIsHidingMobileMenu(true);

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setIsMobileMenuVisible(false);

                setIsHidingMobileMenu(false);

                resolve();
            }, DELAY_INTERVAL);
        });
    };

    const handleOnNavigateTo = async (to: string) => {
        if (isMobileMenuVisible) {
            await handleHideMobileMenu();
        }

        props.onNavigateTo(to);
    };

    const handleShowSelectImprovementForm = async () => {
        const payload: IShowModalPayload = {
            callerAddress: location.pathname,
            type: ModalTypes.select_improvement_form,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowSelectChecklistTemplate = async () => {
        const payload: IShowModalPayload = {
            type: ModalTypes.select_checklist_template,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);

        if (isMobileMenuVisible) {
            handleHideMobileMenu();
        }
    };

    const handleSignOutConfirmShow = async () => {
        setIsLogoutConfirmVisible(true);

        if (isMobileMenuVisible) {
            await handleHideMobileMenu();
        }
    };

    const handleSignOutConfirmClose = (value: boolean) => {
        setIsLogoutConfirmVisible(false);

        if (value) {
            props.onSignOut();
        }
    };

    const handleMouseEnter = () => {
        setExpandedState((prev) => {
            if (prev.isOpen === false) {
                return {
                    isFixed: false,
                    isOpen: true,
                };
            }

            return prev;
        });
    };

    const handleMouseLeave = () => {
        setExpandedState((prev) => {
            if (prev.isOpen && !prev.isFixed) {
                return {
                    isFixed: true,
                    isOpen: false,
                };
            }

            return prev;
        });
    };

    const handleMyProfileClick = async () => {
        if (isMobileMenuVisible) {
            await handleHideMobileMenu();
        }
        navigate("/profile");
    };

    const handleHelpAndSupportClick = () => {
        window.open(
            localeId === LocaleId.En
                ? "https://www.ampliflow.com/support"
                : "https://www.ampliflow.se/support",
            "_blank",
        );
    };

    const handleLanguageChange = async () => {
        if (isMobileMenuVisible) {
            await handleHideMobileMenu();
        }
        const newLocaleId =
            localeId === LocaleId.Sv ? LocaleId.En : LocaleId.Sv;
        setLocale(newLocaleId);
        props.updateLocale(newLocaleId);
    };

    const className = useMemo(() => {
        const result = ["no-print", "menu-section"];

        if (expandedState.isOpen) {
            if (expandedState.isFixed) {
                result.push("expanded");
            } else {
                result.push("expanded-panel");
            }
        }

        return result.join(" ");
    }, [expandedState]);

    const wrapperClassNames = useMemo(() => {
        const list: string[] = ["menu-section__wrapper"];

        if (expandedState.isOpen) {
            if (expandedState.isFixed) {
                list.push("expanded");
            } else {
                list.push("expanded-panel");
            }
        }

        if (isMobileMenuVisible) {
            list.push("show-mobile");
        }

        if (isHidingMobileMenu) {
            list.push("hidding");
        }

        return list.join(" ");
    }, [expandedState, isMobileMenuVisible, isHidingMobileMenu]);

    const {
        templateMenuOptions,
        seniorManagementMenuOptions,
        marketingSalesMenuOptions,
        sourcingPurchaseMenuOptions,
        hRMenuOptions,
        otherMenuOptions,
        riskManagementMenuOptions,
        administratorMenuOptions,
        reportsMenuOptions,
        performanceManagementMenuOptions,
    } = useNavMenuOptions(appFeatures, roles);

    const customListMenuOptionsSplitted = appFeatures.customLists
        ? customListLinks?.menuOptions.reduce(
              (acc, item) => {
                  const showUnderNavNode = Object.values(
                      customListLinks.values.values,
                  ).find((value) => value?.id === item.value)?.showUnderNavNode;

                  if (showUnderNavNode !== undefined) {
                      if (!acc[showUnderNavNode]) {
                          acc[showUnderNavNode] = [];
                      }
                      acc[showUnderNavNode].push(item);
                  }
                  return acc;
              },
              {} as Record<IMenuNavNodes, IMenuOption[]>,
          )
        : [];

    const optionsExtended = {
        performanceManagementMenuOptions: [
            ...performanceManagementMenuOptions,
            ...(customListMenuOptionsSplitted?.[
                IMenuNavNodes.PerformanceManagement
            ] ?? []),
        ],
        seniorManagementMenuOptions: [
            ...seniorManagementMenuOptions,
            ...(customListMenuOptionsSplitted?.[
                IMenuNavNodes.SeniorManagement
            ] ?? []),
        ],
        marketingSalesMenuOptions: [
            ...marketingSalesMenuOptions,
            ...(customListMenuOptionsSplitted?.[
                IMenuNavNodes.MarketingAndSales
            ] ?? []),
        ],
        sourcingPurchaseMenuOptions: [
            ...sourcingPurchaseMenuOptions,
            ...(customListMenuOptionsSplitted?.[
                IMenuNavNodes.SourcingAndPurchase
            ] ?? []),
        ],
        hRMenuOptions: [
            ...hRMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.HR] ?? []),
        ],
        otherMenuOptions: [
            ...otherMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.OtherResources] ??
                []),
        ],
        riskManagementMenuOptions: [
            ...riskManagementMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.RiskManagement] ??
                []),
        ],
        administratorMenuOptions: [
            ...administratorMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.Administrator] ??
                []),
        ],
        reportsMenuOptions: [
            ...reportsMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.Reports] ?? []),
        ],
        templateMenuOptions: [
            ...templateMenuOptions,
            ...(customListMenuOptionsSplitted?.[IMenuNavNodes.Templates] ?? []),
        ],
        customListMenuOptions:
            customListMenuOptionsSplitted?.[IMenuNavNodes.CustomLists] ?? [],
        maintenanceMenuOptions:
            customListMenuOptionsSplitted?.[IMenuNavNodes.Maintenance] ?? [],
        itMenuOptions: customListMenuOptionsSplitted?.[IMenuNavNodes.IT] ?? [],
    };

    return (
        <>
            <MobileMenuHeader
                show={isMobileMenuVisible}
                isClosing={isHidingMobileMenu}
                logoPath={logoPath}
                onNavigateTo={handleOnNavigateTo}
                onToggle={handleToggleMobileMenu}
            />
            <div className={className}>
                {isLogoutConfirmVisible && (
                    <Confirm
                        confirmBody={t(k.LOGOUT_CONFIRMATION)}
                        onClose={handleSignOutConfirmClose}
                    />
                )}

                <div
                    className={wrapperClassNames}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="menu-section__nodes__header">
                        <MenuNode.Link
                            className="menu-section--logo"
                            to="/"
                            onSelect={handleOnNavigateTo}
                        >
                            <img
                                src={
                                    expandedState.isOpen
                                        ? logoPath
                                        : logoSmallPath
                                }
                                alt="Logo"
                            />
                        </MenuNode.Link>

                        <Button
                            className="btn--quick-run"
                            icon={<AiFillThunderbolt />}
                            onClick={handleShowSelectChecklistTemplate}
                            noMinWidth
                        >
                            {expandedState.isOpen &&
                                t(k.NAV_BUTTON_RUN_CHECKLIST)}
                        </Button>

                        <Button
                            className="btn--deviation"
                            icon={<TiWarningOutline />}
                            onClick={handleShowSelectImprovementForm}
                            noMinWidth
                        >
                            {expandedState.isOpen &&
                                t(k.NAV_BUTTON_REGISTER_IMPROVEMENT)}
                        </Button>
                    </div>

                    <div className="menu-section--nodes-body">
                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<FiCheck />}
                            label={t(k.NAV_NODE_CHECKLISTS)}
                            to="/"
                            exact
                            onSelect={handleOnNavigateTo}
                        />

                        {appFeatures.tasks && (
                            <MenuNode
                                expanded={expandedState.isOpen}
                                icon={<FaTasks />}
                                label={t(k.NAV_NODE_TASKS)}
                                to="/tasks"
                                onSelect={handleOnNavigateTo}
                            />
                        )}

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<TiWarningOutline />}
                            label={t(k.NAV_NODE_IMPROVEMENTS)}
                            to="/improvements"
                            onSelect={handleOnNavigateTo}
                        />

                        {appFeatures.processChart && (
                            <MenuNode
                                expanded={expandedState.isOpen}
                                icon={<ProcessChartIcon variant="white" />}
                                label={t(k.NAV_NODE_PROCESSCHART)}
                                to="/processchart"
                                paths={ProcessChartPaths}
                                onSelect={handleOnNavigateTo}
                            />
                        )}

                        {appFeatures.pages && (
                            <MenuNode
                                expanded={expandedState.isOpen}
                                icon={<HiDocumentDuplicate />}
                                label={t(k.NAV_NODE_PAGES)}
                                to="/pages/folder/root"
                                paths={PagesPaths}
                                onSelect={handleOnNavigateTo}
                            />
                        )}

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<MdSpeed />}
                            label={t(k.NAV_NODE_PERFORMANCE_MANAGEMENT)}
                            menuOptions={
                                optionsExtended.performanceManagementMenuOptions
                            }
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<FaRegFileAlt />}
                            label={t(k.NAV_NODE_TEMPLATES)}
                            menuOptions={optionsExtended.templateMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<SiGoogleanalytics />}
                            label={t(k.NAV_NODE_REPORTS)}
                            menuOptions={optionsExtended.reportsMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        {appFeatures.customLists && (
                            <MenuNode
                                expanded={expandedState.isOpen}
                                icon={<CustomListIcon />}
                                label={t(k.NAV_NODE_CUSTOM_LISTS)}
                                paths={CustomListItemsPaths}
                                menuOptions={
                                    optionsExtended.customListMenuOptions
                                }
                                onSelect={handleOnNavigateTo}
                                nodeOptionClassName="sentry-mask"
                            />
                        )}

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<SeniorManagementIcon />}
                            label={t(k.NAV_NODE_SENIOR_MANAGEMENT)}
                            menuOptions={
                                optionsExtended.seniorManagementMenuOptions
                            }
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<MarketingSalesIcon />}
                            label={t(k.NAV_NODE_MARKETING_SALES)}
                            menuOptions={
                                optionsExtended.marketingSalesMenuOptions
                            }
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<SourcingPurchaseIcon />}
                            label={t(k.NAV_NODE_SOURCING_PURCHASE)}
                            menuOptions={
                                optionsExtended.sourcingPurchaseMenuOptions
                            }
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<HRIcon />}
                            label={t(k.NAV_NODE_HR)}
                            menuOptions={optionsExtended.hRMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<ResourcesIcon />}
                            label={t(k.NAV_NODE_OTHER_RESOURCES)}
                            menuOptions={optionsExtended.otherMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<RiskManagementIcon />}
                            label={t(k.NAV_NODE_RISK_MANAGEMENT)}
                            menuOptions={
                                optionsExtended.riskManagementMenuOptions
                            }
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<GrHostMaintenance />}
                            label={t(k.NAV_NODE_MAINTENANCE)}
                            menuOptions={optionsExtended.maintenanceMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        <MenuNode
                            expanded={expandedState.isOpen}
                            icon={<FaLaptopCode />}
                            label={t(k.NAV_NODE_IT)}
                            menuOptions={optionsExtended.itMenuOptions}
                            onSelect={handleOnNavigateTo}
                        />

                        {roles.ADMINISTRATOR && (
                            <MenuNode
                                expanded={expandedState.isOpen}
                                icon={<MdSecurity />}
                                label={t(k.NAV_NODE_ADMINISTRATOR)}
                                menuOptions={
                                    optionsExtended.administratorMenuOptions
                                }
                                onSelect={handleOnNavigateTo}
                            />
                        )}
                    </div>

                    <div className="menu-section--nodes-footer">
                        <MenuNode.Divider />
                        {!expandedState.isOpen && !isMobileMenuVisible ? (
                            <div className="menu-section--column">
                                <Avatar name={profile?.username} />
                                {profile?.localeId === LocaleId.Sv ? (
                                    <div className="country-flag">{svFlag}</div>
                                ) : (
                                    <div className="country-flag">{gbFlag}</div>
                                )}
                                <IconButton
                                    background="dark"
                                    onClick={handleToggleExpand}
                                >
                                    <FiChevronsRight color="white" />
                                </IconButton>
                            </div>
                        ) : (
                            <div className="menu-section--row">
                                <div className="menu-section--row--segment">
                                    {!isMobileMenuVisible && (
                                        <IconButton
                                            background="dark"
                                            onClick={handleToggleExpand}
                                        >
                                            {expandedState.isFixed ? (
                                                <FiChevronsLeft color="white" />
                                            ) : (
                                                <FiChevronsRight color="white" />
                                            )}
                                        </IconButton>
                                    )}
                                    <Avatar
                                        tooltipMessage={t(k.MY_PROFILE)}
                                        name={profile?.username}
                                        onClick={handleMyProfileClick}
                                        className="menu-section--avatar"
                                    />
                                </div>
                                <div className="menu-section--row--segment">
                                    <Tooltip
                                        id="language-tooltip"
                                        message={
                                            profile?.localeId === LocaleId.Sv
                                                ? t(
                                                      k.CHANGE_LANGUAGE_TO_ENGLISH,
                                                  )
                                                : t(
                                                      k.CHANGE_LANGUAGE_TO_SWEDISH,
                                                  )
                                        }
                                    >
                                        {profile?.localeId === LocaleId.Sv ? (
                                            <div
                                                className="country-flag"
                                                onClick={handleLanguageChange}
                                            >
                                                {svFlag}
                                            </div>
                                        ) : (
                                            <div
                                                className="country-flag"
                                                onClick={handleLanguageChange}
                                            >
                                                {gbFlag}
                                            </div>
                                        )}
                                    </Tooltip>
                                    <KebabMenu
                                        onSignOut={handleSignOutConfirmShow}
                                        onHelpAndSupportClick={
                                            handleHelpAndSupportClick
                                        }
                                        onMyProfileClick={handleMyProfileClick}
                                        onLanguageChange={handleLanguageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
const actions = {
    updateLocale,
};

export default connect(null, actions)(MenuSection);
