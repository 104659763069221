import "./SetTargets.scss";

import { Dispatch, RefObject, SetStateAction, useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IKpiFormats } from "../../api/IKpiFormats";
import { SetFormat } from "../format/SetFormat";
import KpiThresholdRange from "./components/KpiThresholdRange";
import YearlyTable from "common/components/YearlyTable/YearlyTable";
import { InfoIcon } from "common/components/icons";
import InputWrapper from "common/components/input-components/InputWrapper";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { IKpiTargetDTO } from "components/kpi-page/api/IKpiTargetDTO";
import { buildNextTargetValues, newYear } from "components/kpi-page/api/utils";

const defaultData = newYear();

export const DEFAULT_MIN_THRESHOLD = 80;
export const DEFAULT_MAX_THRESHOLD = 100;

interface IProps {
    stepRef: RefObject<HTMLDivElement>;
    dataId: string;
    id?: string;

    errors?: IValidationMessages;

    kpiFormats: IKpiFormats;

    onChangeKpiFormats: (value: IKpiFormats) => void;

    target: IKpiTargetDTO;
    setTarget: Dispatch<SetStateAction<IKpiTargetDTO>>;
    onHaveChanges: (haveChanges: boolean) => void;
}

const SetTargets = (props: IProps) => {
    const { kpiFormats, errors, stepRef, dataId, target, setTarget } = props;

    const { t } = useTranslation();

    const [currentYear, setCurrentYear] = useState(defaultData.year);

    const handleSetThresholdValues = (values: {
        min?: number;
        max?: number;
    }) => {
        setTarget((prev) => ({
            ...prev,
            overThreshold: values.max ?? prev.overThreshold,
            underThreshold: values.min ?? prev.underThreshold,
        }));

        props.onHaveChanges(true);
    };

    const handleChangeYear = (year: number) => {
        setCurrentYear(year);

        setTarget((prev) => {
            const isUpdate = prev.yearList.includes(year);

            const nextKpiTargetValueData = newYear({
                nextYear: year,
                sourceKpi: prev,
            });

            return {
                ...prev,

                yearList: isUpdate
                    ? prev.yearList
                    : prev.yearList.concat(year).sort((a, b) => a - b),

                values: {
                    ...prev.values,
                    ...nextKpiTargetValueData.kpiTargetValues,
                },

                kpiTargetValueIdsByYear: {
                    ...prev.kpiTargetValueIdsByYear,
                    [year]: nextKpiTargetValueData.kpiTargetValueIds,
                },
            };
        });

        props.onHaveChanges(true);
    };

    const handleChange = (param: { id: string; value: string }) => {
        const { id, value } = param;

        setTarget((prev) => {
            const existingValue = prev.values[id];

            if (existingValue) {
                const nextValues = buildNextTargetValues(
                    id,
                    value,
                    existingValue,
                    prev,
                );

                return {
                    ...prev,

                    values: {
                        ...prev.values,

                        ...nextValues,
                    },
                };
            }

            return prev;
        });

        props.onHaveChanges(true);
    };

    const handleChangeOverIsGood = (value: boolean) => {
        setTarget((prev) => ({ ...prev, overIsGood: value }));

        props.onHaveChanges(true);
    };

    const handleChangeKpiFormats = (value: IKpiFormats) => {
        props.onChangeKpiFormats(value);

        props.onHaveChanges(true);
    };

    return (
        <div className="set-targets" ref={stepRef} data-id={dataId}>
            <SetFormat
                errors={errors}
                kpiFormats={kpiFormats}
                overIsGood={target.overIsGood}
                onChangeFormats={handleChangeKpiFormats}
                onChangeOverIsGood={handleChangeOverIsGood}
            />

            <InputWrapper
                htmlFor="kpi-form-targets-wrapper"
                wrapperLabel={
                    <div className="kpi-form--body--inputs-label">
                        {t(k.KPI_TARGETS)}

                        <InfoIcon
                            size="small"
                            tooltip={{
                                id: "kpi-form-target-info",
                                message: t(k.KPI_TARGETS_INFO),
                            }}
                        />
                    </div>
                }
                boldLabel
            >
                <YearlyTable
                    year={currentYear}
                    values={target.values}
                    idsByYear={target.kpiTargetValueIdsByYear?.[currentYear]}
                    onChangeYear={handleChangeYear}
                    onChange={handleChange}
                />

                {errors?._error?.map((x, i) => (
                    <ValidationLabel
                        key={i}
                        errors={t(k[x as keyof typeof k])}
                    />
                ))}
            </InputWrapper>

            <InputWrapper
                wrapperLabel={t(k.CUSTOMIZE_THRESHOLD_VALUES)}
                boldLabel
            >
                <KpiThresholdRange
                    values={{
                        min: target.underThreshold ?? DEFAULT_MIN_THRESHOLD,
                        max: target.overThreshold ?? DEFAULT_MAX_THRESHOLD,
                    }}
                    onChange={handleSetThresholdValues}
                    highIsGood={target.overIsGood}
                />
            </InputWrapper>
        </div>
    );
};

export default SetTargets;
