import { TFunction } from "i18next";

import k from "i18n/keys";

export enum IMenuNavNodes {
    PerformanceManagement,
    Templates,
    Reports,
    CustomLists,
    SeniorManagement,
    MarketingAndSales,
    SourcingAndPurchase,
    HR,
    OtherResources,
    RiskManagement,
    Administrator,
    Maintenance,
    IT
}

export const navNodeLabelByValue = (t: TFunction<"translation", undefined>) => {
    return {
        [IMenuNavNodes.PerformanceManagement]: t(
            k.NAV_NODE_PERFORMANCE_MANAGEMENT,
        ),
        [IMenuNavNodes.Templates]: t(k.NAV_NODE_TEMPLATES),
        [IMenuNavNodes.Reports]: t(k.NAV_NODE_REPORTS),
        [IMenuNavNodes.CustomLists]: t(k.NAV_NODE_CUSTOM_LISTS),
        [IMenuNavNodes.SeniorManagement]: t(k.NAV_NODE_SENIOR_MANAGEMENT),
        [IMenuNavNodes.MarketingAndSales]: t(k.NAV_NODE_MARKETING_SALES),
        [IMenuNavNodes.SourcingAndPurchase]: t(k.NAV_NODE_SOURCING_PURCHASE),
        [IMenuNavNodes.HR]: t(k.NAV_NODE_HR),
        [IMenuNavNodes.OtherResources]: t(k.NAV_NODE_OTHER_RESOURCES),
        [IMenuNavNodes.RiskManagement]: t(k.NAV_NODE_RISK_MANAGEMENT),
        [IMenuNavNodes.Administrator]: t(k.NAV_NODE_ADMINISTRATOR),
        [IMenuNavNodes.Maintenance]: t(k.NAV_NODE_MAINTENANCE),
        [IMenuNavNodes.IT]: t(k.NAV_NODE_IT),
    };
};
