import "./ActivityPhoto.scss";

import React from "react";

import { IInputChangeEvent } from "../input-components/IInputProps";
import FormTask from "../task/FormTask";

interface IProps {
    id: string;
    photoId?: string;
    value: string;

    disabled: boolean;
    completed?: boolean;

    label?: React.ReactNode;

    testId?: string;

    onChange?: (e: IInputChangeEvent<string>) => void;
}

const ActivityPhoto = (props: IProps) => {
    return <FormTask {...props} className="activity-input__wrapper" />;
};

export default ActivityPhoto;
