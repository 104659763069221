import "./Photofield.scss";

import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import { IInputChangeEvent, IInputProps, IWithDebounce } from "../IInputProps";
import { PhotoIcon } from "common/components/icons";
import PreviewIcon from "common/components/icons/icon-preview/PreviewIcon";
import UploadCloudIcon from "common/components/icons/icon-upload-cloud/UploadCloudIcon";
import ImageCard from "common/components/images/image-preview/ImageCard";
import ImagePreview from "common/components/images/image-preview/ImagePreview";
import UploadFile from "common/components/upload/UploadFile";
import WebCamModal from "common/components/web-cam-modal/WebCamModal";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { showBlobImage, showImage } from "store/images/actions";

export interface IPhotoFieldProps extends IWithDebounce {}

type Props<V> = IInputProps<string> & IPhotoFieldProps;

const Photofield = <V,>(props: Props<V>) => {
    const {
        id,
        disabled,
        preview,
        value,
        wrapperClassName,
        completedAt,
        blobSrc,
        invalid,
        error,
    } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isWebCamVisible, setIsWebCamVisible] = useState(false);

    const isDisabled = disabled || preview;

    const showPreview = value || blobSrc;

    const handleOnLoad = (content: string, fileName: string) => {
        props.onChange?.({
            id,
            value: content,
            param: fileName,
        });
    };

    const handleShowWebCamModal = () => {
        setIsWebCamVisible(true);
    };

    const handleOnWebCamChange = (e: IInputChangeEvent<string>) => {
        setIsWebCamVisible(false);

        props.onChange?.({
            ...e,
            id,
        });
    };

    const handleOnWebCamClose = () => {
        setIsWebCamVisible(false);
    };

    const handleOnShowPreview = () => {
        if (value) {
            dispatch<any>(showImage(value));
        } else if (blobSrc) {
            dispatch<any>(showBlobImage(blobSrc));
        }
    };

    const className = useMemo(() => {
        const result = ["photo-field__wrapper"];

        if (invalid) {
            result.push("photo-field__wrapper--invalid");
        }

        if (wrapperClassName) {
            result.push(wrapperClassName);
        }

        return result.join(" ");
    }, [invalid, wrapperClassName]);

    return (
        <div className={className}>
            {isDisabled ? (
                showPreview ? (
                    <ImagePreview
                        value={value}
                        blobSrc={blobSrc}
                        completedAt={completedAt}
                    />
                ) : (
                    <div className="photo-field__input">
                        <PhotoIcon disabled={true} />
                    </div>
                )
            ) : (
                <React.Fragment>
                    <WebCamModal
                        show={isWebCamVisible}
                        onChange={handleOnWebCamChange}
                        onClose={handleOnWebCamClose}
                    />

                    {showPreview ? (
                        <div className="photo-field--image-card-selector photo-field--large">
                            <ImageCard
                                value={value}
                                blobSrc={blobSrc}
                                completedAt={completedAt}
                            >
                                <div
                                    data-testid="photo-field-preview-btn"
                                    className="photo-field__input photo-field--large photo-field__input-active"
                                    onClick={handleOnShowPreview}
                                >
                                    <PreviewIcon />
                                    {t(k.PREVIEW)}
                                </div>
                                <div
                                    data-testid="photo-field-replace-btn"
                                    className="photo-field__input photo-field--large photo-field__input-active"
                                    onClick={handleShowWebCamModal}
                                >
                                    <UploadCloudIcon />
                                    {t(k.REPLACE)}
                                </div>
                            </ImageCard>

                            {props.clearControl && (
                                <div className="photo-field--clear">
                                    {props.clearControl}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            data-testid="photo-field-add-btn"
                            className="photo-field__input photo-field--large photo-field__input-active"
                            onClick={handleShowWebCamModal}
                        >
                            <PhotoIcon />
                        </div>
                    )}

                    <div className="photo-field--image-card-selector photo-field--small">
                        <UploadFile
                            className={
                                showPreview ? undefined : "photo-field__input"
                            }
                            onLoad={handleOnLoad}
                            onlyImages={true}
                        >
                            {showPreview ? (
                                <ImageCard
                                    value={value}
                                    blobSrc={blobSrc}
                                    completedAt={completedAt}
                                />
                            ) : (
                                <PhotoIcon />
                            )}
                        </UploadFile>

                        {showPreview && props.clearControl && (
                            <div className="photo-field--clear">
                                {props.clearControl}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}

            {error && (
                <div className="text-field--helper-text">
                    <ValidationLabel
                        errors={
                            typeof error === "string" && error in k
                                ? t(k[error as keyof typeof k])
                                : error
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default Photofield;
