import { registerLocale, setDefaultLocale } from "react-datepicker";

import i18next from "i18next";

import UsLocale from "date-fns/locale/en-US";
import SvLocale from "date-fns/locale/sv";

export enum LocaleId {
    En = "en",
    Sv = "sv",
}

const AppLocale = {
    locale: UsLocale,
};

let currentLocaleId: LocaleId | undefined;

const setLocale = (localeId: LocaleId) => {
    currentLocaleId = localeId;

    switch (localeId) {
        case LocaleId.Sv:
            AppLocale.locale = SvLocale;

            break;

        default:
            AppLocale.locale = UsLocale;

            break;
    }

    i18next.changeLanguage(localeId, () => {
        registerLocale(localeId, AppLocale.locale);
        setDefaultLocale(localeId);
    });
};

const languageToLocale = (language: string) => {
    const key = language.split("-")[0];

    if (Object.values(LocaleId).includes(key as LocaleId)) {
        return key as LocaleId;
    }

    return LocaleId.En;
};

const formatDecimalWithLocale = (value: number, useGrouping = false) => {
    return Intl.NumberFormat(AppLocale.locale.code, {
        style: "decimal",
        maximumFractionDigits: 10,
        useGrouping,
    })
        .format(value)
        .replace(/\s/g, "");
};

const parseDecimalWithLocale = (value: string) => {
    const decimalSeparator = AppLocale.locale.code === LocaleId.Sv ? "," : ".";
    const thousandsSeparator =
        AppLocale.locale.code === LocaleId.Sv ? "." : ",";

    const normalizedValue = value
        .replace(thousandsSeparator, "")
        .replace(decimalSeparator, ".");

    return parseFloat(normalizedValue);
};

export {
    AppLocale,
    currentLocaleId,
    languageToLocale,
    setLocale,
    formatDecimalWithLocale,
    parseDecimalWithLocale,
};
