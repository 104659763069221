import { useContext, useEffect } from "react";

import { IModalListItem } from "../../api/IModalManager";
import ModalContent from "common/components/modal-content/ModalContent";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import ChecklistListedContent from "components/checklists/components/ChecklistListedContent/ChecklistListedContent";
import { usePublishedUserOptions } from "components/users/api/hooks";

interface IProps {
    modalItem: IModalListItem;
}

const ChecklistDetailsModal: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        modalItem: { callId, id, options },
    } = props;

    const { scrollToElement } = options || {};

    const modalManagerContext = useContext(ModalManagerContext);

    const handleOnClose = (onSave?: boolean) => {
        modalManagerContext.onCloseModal({ callId, onSave });
    };

    usePublishedUserOptions();

    const handleOnHaveChanges = (haveChanges: boolean) => {
        modalManagerContext.onModalHaveChanges({ callId, haveChanges });
    };

    return (
        <ModalContent>
            <ChecklistListedContent
                scrollToElement={scrollToElement}
                onShowModal={modalManagerContext.onShowModal}
                onHaveChanges={handleOnHaveChanges}
                onClose={handleOnClose}
                id={id ?? ""}
                checkCanSeeAll={options?.showAll ?? false}
                syncData={modalManagerContext.state.activeModal === callId}
            />
        </ModalContent>
    );
};

export default ChecklistDetailsModal;
