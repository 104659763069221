import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import { IBlobSrcType } from "../../api/ISavedSharedPropertyInstanceValue";
import { ISharedPropertyInputProps } from "../SharedPropertyInput";
import IconButton from "common/components/buttons/icon-button/IconButton";
import TrashIcon from "common/components/icons/icon-trash/TrashIcon";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import { IShowConfirmArgs, showConfirmDispatch } from "store/confirms/actions";

const CustomListItemPhotoSelector = (
    props: ISharedPropertyInputProps<IBlobSrcType>,
) => {
    const { values, errors, disabled } = props;

    const { parentId, isRequired, label } = props.property;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleChange = (e: IInputChangeEvent<string>) => {
        const blobSrc: IBlobSrcType = {
            fileName: null,
            value: e.value,
        };

        props.onChange(parentId, [blobSrc]);
    };

    const handleUndo = async () => {
        const args: IShowConfirmArgs = {
            message: t(k.ARE_YOU_SURE),
        };

        if (await showConfirmDispatch(dispatch, args)) {
            props.onChange(parentId, []);
        }
    };

    return (
        <InputComponent
            inputType={EActivityType.Photo}
            id={`form.${parentId}`}
            value=""
            blobSrc={values && values.length > 0 ? values[0] : undefined}
            invalid={Boolean(errors[parentId])}
            wrapperLabel={label}
            required={isRequired}
            disabled={disabled}
            clearControl={
                <IconButton circle={true} onClick={handleUndo}>
                    <TrashIcon dotIcon={true} />
                </IconButton>
            }
            errors={errors[parentId]}
            onChange={handleChange}
        />
    );
};

export default CustomListItemPhotoSelector;
