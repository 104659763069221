import { FC, useContext, useEffect, useState } from "react";
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import ChecklistDetailsContext from "../../checklistContext";
import { IValueLabelItem } from "common/IValueLabelItem";
import { IConfirmData } from "common/components/listed-content/models/IConfirmData";
import StepInstanceCardById from "common/components/step-instance-card/StepInstanceCardById";
import { IChangeTypes } from "common/components/step-instance-card/StepInstanceCardById";
import ICheckRemoveTeamRequest from "http/requests/ICheckRemoveTeamRequest";
import { IChangedStepInstance } from "http/requests/IWorkflowRunRequest";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";
import { checkRemoveTeamDispatch } from "store/stepInstances/actions";

interface IProps {}

const Content: FC<IProps> = (props) => {
    const {
        lastFinalizedStepInstanceId,
        stepInstances,
        setEditedSteps,
        setUsersAffected,
        onHaveChanges,
        sectionRefs,
        scrollToTarget,
        scrollToElementOnLoad,
    } = useContext(ChecklistDetailsContext);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [scrollFinished, setScrollFinished] = useState(false);

    useEffect(() => {
        if (
            scrollToElementOnLoad &&
            sectionRefs?.[scrollToElementOnLoad]?.current &&
            scrollToTarget &&
            !scrollFinished
        ) {
            scrollToTarget({
                targetId: scrollToElementOnLoad,
                sectionRefs,
                timeout: 0,
            });
            setScrollFinished(true);
        }
    }, [scrollToElementOnLoad, sectionRefs, scrollToTarget]);

    const handleStepInstanceChange = async <T extends keyof IChangeTypes>(
        id: string,
        changeType: T,
        data: IChangeTypes[T],
    ) => {
        switch (changeType) {
            case "team":
                if (data) {
                    let siChangeType: keyof IChangedStepInstance;

                    let change: boolean | string[];

                    if (data.team.value === "teamEveryone") {
                        siChangeType = "isTeamEveryone";
                    } else {
                        siChangeType = "teamIds";
                    }

                    if (data.remove) {
                        const request: ICheckRemoveTeamRequest = {
                            stepInstanceId: id,
                            remainingTeamIds: data.currentIds?.filter(
                                (x) => x !== data.team.value,
                            ),
                        };

                        if (data.team.value === "teamEveryone") {
                            request.teamEveryone = true;
                        } else {
                            request.teamId = data.team.value;
                        }

                        const checkRemoveTeam = await checkRemoveTeamDispatch(
                            dispatch,
                            request,
                        );

                        if (checkRemoveTeam.removesEditor) {
                            const confirmData: IConfirmData = {
                                removesEditor: true,
                                usersAffected: checkRemoveTeam.usersAffected,
                                team: data.team,
                            };

                            const args: IShowConfirmArgs = {
                                title: t(k.REMOVE_SELF_STEP_INSTANCE_TITLE),
                                message: confirmBodyMaker(confirmData),
                                yesLabel: t(k.REMOVE_SELF_STEP_INSTANCE_YES),
                                modalClass: "modal-warning",
                                yesButtonVariant: "warning",
                            };

                            if (await showConfirmNoThunk(dispatch, args)) {
                                change =
                                    siChangeType === "teamIds"
                                        ? data.currentIds.filter(
                                              (x) => x !== data.team.value,
                                          )
                                        : false;

                                updateEditedSteps(
                                    id,
                                    siChangeType,
                                    change,
                                    checkRemoveTeam.usersAffected,
                                    true,
                                );
                            }
                        } else if (checkRemoveTeam.usersAffected?.length) {
                            const confirmData: IConfirmData = {
                                removesEditor: false,
                                usersAffected: checkRemoveTeam.usersAffected,
                                team: data.team,
                            };

                            const args: IShowConfirmArgs = {
                                title: t(k.UN_ASSIGN_USERS_STEP_INSTANCE_TITLE),
                                message: confirmBodyMaker(confirmData),
                                yesLabel: t(
                                    k.UN_ASSIGN_USERS_STEP_INSTANCE_YES,
                                ),
                                modalClass: "modal-warning",
                                yesButtonVariant: "warning",
                            };

                            if (await showConfirmNoThunk(dispatch, args)) {
                                change =
                                    siChangeType === "teamIds"
                                        ? data.currentIds.filter(
                                              (x) => x !== data.team.value,
                                          )
                                        : false;

                                updateEditedSteps(
                                    id,
                                    siChangeType,
                                    change,
                                    checkRemoveTeam.usersAffected,
                                );
                            }
                        } else {
                            change =
                                siChangeType === "teamIds"
                                    ? data.currentIds.filter(
                                          (x) => x !== data.team.value,
                                      )
                                    : false;

                            updateEditedSteps(
                                id,
                                siChangeType,
                                change,
                                checkRemoveTeam.usersAffected,
                            );
                        }
                    } else {
                        change =
                            siChangeType === "teamIds"
                                ? data.currentIds.concat([data.team.value])
                                : true;

                        updateEditedSteps(id, siChangeType, change);
                    }
                }

                break;
            default:
                throw new Error(
                    `Type ${changeType} does not exist. No changes where made.`,
                );
        }
    };

    const updateEditedSteps = <T extends keyof IChangedStepInstance>(
        id: string,
        type: T,
        data: IChangedStepInstance[T],
        usersAffected?: IValueLabelItem<string, string>[],
        removeEditor?: boolean,
    ) => {
        setEditedSteps((prev) =>
            prev
                ? {
                      ...prev,
                      [id]: prev[id]
                          ? { ...prev[id], [type]: data, removeEditor }
                          : { id, [type]: data },
                  }
                : { [id]: { id, [type]: data } },
        );
        if (usersAffected) {
            const userIds = usersAffected.map((x) => x.value);
            setUsersAffected((prev) => ({ ...prev, [id]: userIds }));
        }

        onHaveChanges(true);
    };

    const confirmBodyMaker = (showConfirm: IConfirmData) => {
        const { removesEditor, usersAffected, team } = showConfirm;

        let result: JSX.Element;

        const userNames = usersAffected?.length
            ? usersAffected.map((x, index, array) => (
                  <React.Fragment key={x.value}>
                      {index === 0 ? (
                          <b>{x.label}</b>
                      ) : array.length - 1 ? (
                          <>
                              {" "}
                              {t(k.AND_)} <b>{x.label}</b>
                          </>
                      ) : (
                          <>
                              {" "}
                              <b>{x.label}</b>
                          </>
                      )}
                  </React.Fragment>
              ))
            : undefined;

        if (removesEditor) {
            if (userNames) {
                result = (
                    <>
                        {t(k.REMOVE_SELF_STEP_INSTANCE_BODY1)}
                        <b>{team.label}</b>
                        {t(k.REMOVE_SELF_STEP_INSTANCE_BODY2)}
                        <br />
                        <br />
                        {userNames}
                        {t(k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY1)}
                        <b>{team.label}</b>
                        {t(k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY2)}
                    </>
                );
            } else {
                result = (
                    <>
                        {t(k.REMOVE_SELF_STEP_INSTANCE_BODY1)}
                        <b>{team.label}</b>
                        {t(k.REMOVE_SELF_STEP_INSTANCE_BODY2)}
                    </>
                );
            }
        } else {
            result = (
                <>
                    {userNames}
                    {t(k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY1)}
                    <b>{team.label}</b>
                    {t(k.UN_ASSIGN_USERS_STEP_INSTANCE_BODY2)}
                </>
            );
        }

        result = (
            <>
                {result}
                <br />
                <br />
                <b>{t(k.CHANGES_APPLY_AFTER_SAVE)}</b>
                {", "}
                {t(k.ARE_YOU_SURE_YOU_WANT_TO_PROCEED)}
            </>
        );
        return result;
    };

    return (
        <>
            {stepInstances?.ids.map((id, index) => (
                <StepInstanceCardById
                    key={
                        lastFinalizedStepInstanceId
                            ? `${id}-${lastFinalizedStepInstanceId}`
                            : id
                    }
                    id={id}
                    orderIndex={index + 1}
                    onChange={handleStepInstanceChange}
                />
            ))}
        </>
    );
};

export default Content;
