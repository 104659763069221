import "./ModalImagePreview.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ImageComponent from "./ImageComponent";
import Button from "common/components/buttons/Button";
import Modal from "common/components/modals/Modal";
import { IBlobSrcType } from "components/common/shared-properties/api/ISavedSharedPropertyInstanceValue";

interface IProps {
    value?: string;
    imgId?: string;
    blobSrc?: IBlobSrcType;

    onHide: () => void;
}

const ModalImagePreview: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { value, imgId, blobSrc } = props;

    const { t } = useTranslation();

    const handleHide = () => {
        props.onHide();
    };

    if (!value && !imgId && !blobSrc) {
        return null;
    }

    return (
        <Modal
            size="xl"
            className="modal-image-preview"
            show
            onHide={handleHide}
        >
            <Modal.Title>{t(k.PREVIEW)}</Modal.Title>
            <Modal.Body>
                <ImageComponent value={value} imgId={imgId} blobSrc={blobSrc} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="gray" onClick={handleHide}>
                    {t(k.CANCEL)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalImagePreview;
