import axios from "axios";

import IChecklistActivityMarkCompleted from "http/requests/IChecklistActivityMarkCompleted";
import { baseActivityInstanceApiUrl } from "./urls";
import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import IActivityInstanceRequest from "http/requests/IActivityInstanceRequest";
import IActivityInstanceResponse from "http/responses/IActivityInstanceResponse";
import { convertToDate, getTimeZone } from "common/utils/time";
import IAssignUserToActivityInstanceRequest from "http/requests/IAssignUserToActivityInstanceRequest";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import IUserDefinedActivityInstanceRequest from "http/requests/IUserDefinedActivityInstanceRequest";
import IActivityInstance from "models/IActivityInstance";
import IActivityInstanceList from "models/dto/IActivityInstanceList";

export function deleteActivityInstanceById(id: string) {
    return axios.delete(`${baseActivityInstanceApiUrl}/${id}`);
}

export function markCompletedActivityInstanceAsync(
    request: IChecklistActivityMarkCompleted,
) {
    return axios.post(`${baseActivityInstanceApiUrl}/mark-completed`, request);
}

export async function saveActivityInstanceAsync(
    request: IActivityInstanceRequest,
) {
    const url =
        request.type === TaskOriginType.Improvement
            ? `${baseActivityInstanceApiUrl}/save-improvement-activity-instance`
            : baseActivityInstanceApiUrl;

    const response = await axios.post<IActivityInstanceResponse>(url, request);

    if (response.data) {
        if (response.data.updatedValue) {
            response.data.updatedValue.updatedAt = convertToDate(
                response.data.updatedValue.updatedAt,
            );
        }

        if (response.data.addedValue) {
            response.data.addedValue.updatedAt = convertToDate(
                response.data.addedValue.updatedAt,
            );
        }
    }

    return response.data;
}

export function assignUserToActivityInstanceAsync(
    request: IAssignUserToActivityInstanceRequest,
) {
    return axios.post<IValueLabelItemWithState[]>(
        `${baseActivityInstanceApiUrl}/assign-user`,
        request,
    );
}

export function saveActivityInstanceTaskValueAsync(
    request: IActivityInstanceRequest,
) {
    const url =
        request.type === TaskOriginType.Improvement
            ? `${baseActivityInstanceApiUrl}/save-improvement-activity-instance`
            : baseActivityInstanceApiUrl;
            
    return axios.post(`${url}`, request);
}

export function addActivityInstanceAsync(
    request: IUserDefinedActivityInstanceRequest,
) {
    const timezone = getTimeZone();

    return axios.post<IActivityInstance>(
        `${baseActivityInstanceApiUrl}/add-activity-instance`,
        { ...request, timezone },
    );
}

export function updateActivityInstanceAsync(
    request: IUserDefinedActivityInstanceRequest,
) {
    const timezone = getTimeZone();

    return axios.put<IActivityInstanceList>(
        `${baseActivityInstanceApiUrl}/update-activity-instance`,
        { ...request, timezone },
    );
}
